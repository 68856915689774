    App.SbtdReturns = {};
    App.SbtdReturns.approve = '1';
    App.SbtdReturns.data = {};
    App.SbtdReturns.userItems = {};
    App.SbtdReturns.UserMakeReturns = false;

    App.ReturnsReturnIndex = function() {
        App.SbtdReturns.LoadOrder();
        App.SbtdReturns.FirstLoad();
    };

    App.SbtdReturns.FirstLoad = function () {
        var order_number = $('#order_number').val();
        if (order_number != ""){
            App.SbtdReturns.ajaxLoad();
        }
    }

    App.SbtdReturns.HideAll = function() {
        $(".after-load").css("display", "none");
    };

    App.SbtdReturns.ShowAll = function() {
        $(".after-load").css("display", "block");
    };

    App.SbtdReturns.FillData = function(data) {
        $("#order_info_number").html(data.number);
        $("#order_info_date").html(data.order_date);
        $("#order_info_link").html(App.SbtdReturns.ShopifyLink(data.shopify_order_id));
        $("#order_info_customer").html(data.customer);
        $("#order_info_email").html(data.email);
        $("#order_info_items_count").html(Object.keys(data.line_items).length);
        $("#order_info_tax").html(data.total_tax);
        $("#order_info_discount").html(data.total_discounts);
        $("#order_info_items_price").html(data.total_price);
        $("#customer").val(data.customer);
        $("#email").val(data.email);

    };

    App.SbtdReturns.CheckBox = function () {
        $('.check').click(function () {
            var parentcheck = $(this).parent();
            if (this.checked) {
                parentcheck.find(".quantity-show").css("display", "block");
                parentcheck.find(".inventory").css("display", "block");
                App.SbtdReturns.Amount(App.SbtdReturns.data);
                if (parentcheck.is(':nth-child(2n+1)') == true) {
                    parentcheck.css("background-color", "#96d796");
                } else {
                    parentcheck.css("background-color", "rgb(127, 174, 127)");
                }
            } else {
                parentcheck.find(".quantity-show").css("display", "none");
                parentcheck.find(".inventory").css("display", "none");
                App.SbtdReturns.Amount(App.SbtdReturns.data);
                if (parentcheck.is(':nth-child(2n+1)') == true) {
                    parentcheck.css("background-color", "#eee");
                } else {
                    parentcheck.css("background-color", "#fff");
                }
            }
        })
        $('.item-image').click(function () {
            $(this).parent().find(".check").click();
        })
    }

    App.SbtdReturns.QuantityChange = function () {
        $('.quantity-select').on('change', function (e) {
            App.SbtdReturns.Amount(App.SbtdReturns.data);
            return false;
        });
    }

    App.SbtdReturns.Amount = function (order) {
        var prods = [];
        var totalDiscount = order.total_discounts;
        var totalTax = order.total_tax;
        var totalAmount = 0;
        var totalPrice = 0;

        $('.check').each(function () {
            var checkbox = $(this).parent();
            if (this.checked) {
                var price = checkbox.find(".get-price").attr("data-price");
                var quantity = checkbox.find(".quantity-select").val();
                totalAmount = totalAmount + (price * quantity);
            }
        });

        $('.check').each(function () {
            var checkbox = $(this).parent();
            var price = checkbox.find(".get-price").attr("data-price");
            var quantity = checkbox.find(".quantity-select").val();
            totalPrice = totalPrice + (price * quantity);
        });

        totalPrice = totalPrice + totalTax;

        if (totalDiscount > 0 && totalAmount > 0) {
            discount_amount_multiplier = totalAmount / totalPrice;
            discount_amount = totalDiscount * discount_amount_multiplier;
            discount_amount = parseFloat(discount_amount.toFixed(2));
            totalAmount = totalAmount - discount_amount;
        }
        if (totalTax > 0 && totalAmount > 0) {
            discount_amount_multiplier = totalAmount / totalPrice;
            discount_amount = totalTax * discount_amount_multiplier;
            discount_amount = parseFloat(discount_amount.toFixed(2));
            totalAmount = totalAmount + discount_amount;
        }

        $("#amount").val(totalAmount);
    };

    App.SbtdReturns.LoadOrder = function() {
        $('#order_number').on('keyup', function (e) {
            var keyCode = e.keyCode || e.which;
            if (keyCode === 13) {
                $(".error-massage").html("");
                App.SbtdReturns.ajaxLoad();
                return false;
            }
        });
        $('#load_order_items').on('click', function (e) {
            $(".error-massage").html("");
            App.SbtdReturns.ajaxLoad();
            return false;
        });
        $('#send_order_items').on('click', function (e) {
            App.SbtdReturns.approve = '1';
            $(".error-massage").html("");
            App.SbtdReturns.ajaxSubmit();
            return false;
        });

        $('#send_order_items_update_details').on('click', function (e) {
            App.SbtdReturns.approve = '0';
            $(".error-massage").html("");
            App.SbtdReturns.ajaxSubmit();
            return false;
        });
        $('#cancel_return').on('click', function (e) {
            $(".error-massage").html("");
            App.SbtdReturns.ajaxSubmitCancel();
            return false;
        });
    };

    App.SbtdReturns.ajaxLoad = function() {
        var order_number = $('#order_number').val();
        if (order_number == '') {
            $(".error-massage").html("Please enter a valid order number");
            return false;
        }
        url = window.location.origin + '/returns/getorderf/' + order_number;
        $.ajax({
            type: "GET",
            url: url,
            success: App.SbtdReturns.LoadOrderCallback,
            error: function(data){
                console.log(data);
                //$(".error-massage").html("Error! Order not found");
            }
        });
    };

    App.SbtdReturns.ajaxSubmitCancel = function() {
        var orderId = $("#orderId").val();
        url = window.location.origin + '/returns/cancelReturn';
        $.ajax({
            type: "POST",
            url: url,
            data: {order_id:orderId},
            success: App.SbtdReturns.RedirectToList,
            error: function(data){
                console.log(data);
                //$(".error-massage").html("Error! Order not found");
            }
        });
    };

    App.SbtdReturns.ajaxSubmit = function () {

        var approve = App.SbtdReturns.approve;
        var userUpdate = App.SbtdReturns.UserMakeReturns;
        var orderId = $("#orderId").val();
        var amount = $("#amount").val();
        var emailTemplate = ($("input:radio[name ='email_template']:checked").val()) ? $("input:radio[name ='email_template']:checked").val() : "";
        var extraAmount = ($("input:radio[name ='extra_amount']:checked").val());
        var labelPrice = 0;
        if ($('.label-checkbox').prop('checked') == true) {labelPrice = $('#shiping_price').val() != "" ? $('#shiping_price').val() : 0}
        var isRefund = $('.refund-checkbox').prop('checked') == true ? "1" : "0";

        if (emailTemplate == ""){
            $(".error-massage").html("Please pick email template");
            return false;
        }
        var note = $("#note").val();
        var items = [];
        $('.check').each(function () {
            var checkbox = $(this);
            item = {};
            if (this.checked) {
                item.productId = checkbox.attr("data-product-id");
                item.quantity = checkbox.parent().find(".quantity-select").val();
                item.inventory = checkbox.parent().find(".update-inventory").prop('checked') ? true : "";
                item.variantId = checkbox.parent().find(".variant").val();
                item.newSku = checkbox.parent().find(".sku_input") != undefined ? checkbox.parent().find(".sku_input").val() : "";
                item.newSku = item.inventory == false ? "" : item.newSku;
            }
            if ($.isEmptyObject(item) == false){
                items.push(item);
            }
        });

        if (items.length == 0) {
            $(".error-massage").html("Please select at least one item");
            return false;
        }
        if (orderId == '') {
            $(".error-massage").html("Please enter a valid order number");
            return false;
        }

        if (App.SbtdReturns.CheckSku() == false) {
            return false;
        }

        url = window.location.origin + '/returns/completeReturns';
        $.ajax({
            type: "POST",
            url: url,
            data: {order_id:orderId, amount:amount, template:emailTemplate, note:note, items:items, userUpdate:userUpdate, extraAmount:extraAmount, labelPrice:labelPrice, approve: approve, isRefund:isRefund},
            success: App.SbtdReturns.ajaxSubmitSuccess,
            error: function(data){
                console.log(data);
                //$(".error-massage").html("Error! Order not found");
            }
        });

    };

    App.SbtdReturns.ajaxSubmitSuccess = function(response) {

        if (response.errors == false) {
            App.SbtdReturns.RedirectToList();
        } else {
            $(".error-massage").html(response.data.message);
        }
    };

    App.SbtdReturns.CheckSku = function () {
        //var skus = $('#items_container').find('.check[data-clicked=1].parent() input.sku_input');
        var success = true;

        $('.check').each(function () {
            if (this.checked) {
                var sheckbox = $(this).parent();
                var skuExists = sheckbox.find(".sku_input").length > 0;
                if (skuExists && sheckbox.find(".update-inventory").prop('checked') == true){
                    var skuValue = sheckbox.find(".sku_input").val();
                    var firstChar = skuValue.charAt(0);
                    var numberPart = parseInt(skuValue.substring(1));
                    var msg = '';
                    if (firstChar != 'R' ) {
                        msg = 'SKU has to start with R';
                        sheckbox.find('.sku_span').html(msg);
                        success = false;
                    }
                    else if (isNaN(numberPart) || numberPart < 1 || numberPart > 500) {
                        msg = 'SKU has to be between 1 and 500 (R123)';
                        success = false;
                    }
                    sheckbox.find('.sku_span').html(msg);
                }
            }
        });
        return success;
    };

    App.SbtdReturns.RedirectToList = function () {
        window.location.href = window.location.origin + '/returns/sbtd_return_list';
    };

    App.SbtdReturns.SubmitOrderCallback = function (response) {
        App.SbtdReturns.HideAll();
        $('#items_container').html('');
        $('#send_order_items').css("display", "none");
        $('#send_order_items_update_details').css("display", "none");
        $('#cancel_return').css("display", "none");
        $('.pick-title').css("display", "none");
        $(".error-massage").html(response.data);

    };

    App.SbtdReturns.LoadOrderCallback = function(response) {
        var order = response.data.returnOrders;
        if (order != null) {
            if (order.status == "Pending"){
                $(".pick-title h4").html("Items are already picked");
            }

            $(".refund-checkbox").prop("checked", order.is_refund);

            if (order.shipment_price != null){
                $("#shiping_price").val(order.shipment_price);
                $("#shiping_status").val(order.shipment_status);
                $(".labela-price").html("$" + order.shipment_price);
                if (order.shipment_status != "unknown" && response.data.damaged == "no") {
                    $(".label-checkbox").prop('checked', true);
                }



            }
            else{
                $(".labela-price").html("$0 No label made");
                $(".label-checkbox").prop("disabled", true);
            }
        }
        $("#order_damaged").val(response.data.damaged);
        $(".damaged").html(response.data.damaged);
        $("#send_order_items").css("display", "block");
        $("#send_order_items_update_details").css("display", "block");
        $("#cancel_return").css("display", "block");
        $(".pick-title").css("display", "block");
        var data = response.data.shopifyOrder;
        var updateInventory = "";
        if (response.data.returnOrders){
            updateInventory = response.data.returnOrders.items;
        };
        App.SbtdReturns.userItems = response.data.returnOrders;
        App.SbtdReturns.data = data;
        App.SbtdReturns.FillData(data);
        $("#orderId").val(data.number);
        $('#items_container').html('');
        var count = 1;
        var currentInventory = 0;
        var currentSku = 0;
        var vendor = "";
        if (data.line_items.length > 1) {
            $('.cleaning-repair-container').css({'display':'none'});
        } else {
            $('.cleaning-repair-container').css({'display':'block'});
        }
        for (var item in data.line_items) {
            $.ajax({
                async: false,
                url:
                '/products/shopify_inventory/' +
                data.line_items[item].product_id.toString() + "/" +
                data.line_items[item].variant_id.toString()
            }).done(function (response, textStatus, jqXHR) {
                currentInventory = response.data.inventory_quantity;
                currentSku = response.data.sku;
                data.line_items[item].currentSku = currentSku;
                vendor = response.data.vendor;
            });

            var checked = "checked";
            if (updateInventory != "") {
                $.each(updateInventory, function (key, value) {
                    if (value.product_id === data.line_items[item].product_id && value.update_inventory === 1) {
                        checked = "checked";
                        return false;
                    } else {
                        checked = "";
                    }
                });
            }

            $('#items_container').append(App.SbtdReturns.LoadLineItemsTemplate(item, data, count, currentInventory, checked, vendor));

            for (i=1; i<data.line_items[item].quantity + 1; i++){
                $(".quantity-length" + count).append(App.SbtdReturns.QuantityShow(i));
                if (currentInventory === 0) {
                    var skuValue = data.line_items[item].sku;

                    var skuFirstPart = skuValue.substr(0,skuValue.indexOf('-'));
                    var skuSecondPart = skuValue.substr(skuValue.indexOf('-')+1);

                    var skuField = '<input class="sku_input" style="width: 50px; color: #000000 !important;" type="text" value="R" name="sku[' + data.line_items[item].variant_id.toString() + ']"> - ' +
                        skuSecondPart + '<span style="padding-left: 10px;">' + currentSku +'</span><span class="sku_span" style="color: #ff0000; padding-left: 10px;"></span>';
                    $(".sku"  + count).html(skuField);
                }
            }
            count++;
        }

        App.SbtdReturns.Amount(data);
        App.SbtdReturns.EmailTemplate(response.data);
        App.SbtdReturns.CheckBox();
        App.SbtdReturns.QuantityChange();
        if(App.SbtdReturns.userItems != null){
            App.SbtdReturns.UserMakeReturns = true;
            App.SbtdReturns.compareUserItems();
        }
        App.SbtdReturns.ShowAll();
    };

    App.SbtdReturns.EmailTemplate = function (data) {
        if (data.returnOrders) {
            $("#note").val(data.returnOrders.note);
            var tempate = data.returnOrders.email_template;
            var extraAmount = data.returnOrders.extra_amount;
            var extraRadio = $("input:radio[name ='extra_amount']");
            $.each(extraRadio, function () {
                if ($(this).val() == extraAmount) {
                    $(this).prop("checked", true);
                }
            });
            var radio = $("input:radio[name ='email_template']");
            $.each(radio, function () {
                if ($(this).val() == tempate) {
                    $(this).prop("checked", true);
                }
            });
        } else {
            var t = data.shopifyOrder.created_at.split(/[- :]/);
            var orderDate = new Date(Date.UTC(t[0], t[1]-1, t[2], t[3], t[4], t[5]));

            var now = new Date();

            var one_day=1000*60*60*24;    // Convert both dates to milliseconds
            var date1_ms = orderDate.getTime();
            var date2_ms = now.getTime();    // Calculate the difference in milliseconds
            var difference_ms = date2_ms - date1_ms;        // Convert back to days and return
            let days = Math.round(difference_ms/one_day);

            if (days >45) {
                $("input:radio[value='over_30_days']").prop('checked', true);
            }

        }

        $("input:radio[name ='email_template']").on('click', function() {
            if (this.value == 'sent_cleaning') {

                $('.update-inventory').prop('checked', false);
                $('.update-inventory').prop('disabled', true);
            } else {
                $('.update-inventory').prop('disabled', false);
            }
        });
    };

    App.SbtdReturns.compareUserItems = function () {
        $.each( App.SbtdReturns.userItems.items, function( key, value ) {
            $('.check').each(function () {
                var checkbox = $(this);
                if (value.product_id == checkbox.attr("data-product-id")) {
                    checkbox.prop("checked", true);
                    checkbox.parent().find(".quantity-select").val(value.quantity);
                    checkbox.parent().find(".quantity-show").css("display", "block");
                    checkbox.parent().find(".inventory").css("display", "block");
                    if(checkbox.parent().is(':nth-child(2n+1)') == true) {
                        checkbox.parent().css( "background-color", "#96d796" );
                    }else{
                        checkbox.parent().css( "background-color", "rgb(127, 174, 127)" );
                    }
                }
            });
        });
        App.SbtdReturns.Amount(App.SbtdReturns.data);
    };

    App.SbtdReturns.LoadLineItemsTemplate = (item, data, count, currentInventory, checked, vendor) => {

        return `
        <div class="row return-entry">
            <input class="check" type="checkbox" value='0' data-item-id="${item}" data-product-id="${data.line_items[item].product_id}">
            <input class="hide variant" type="text" value="${data.line_items[item].variant_id}">
            <img src="${data.line_items[item].image}" class="item-image"/>
            <div class="items-title">
                <div class="prod-name">Name: ${data.line_items[item].name}</div>
                <div class="get-price" data-price="${data.line_items[item].price}">Price:$ ${data.line_items[item].price} &nbsp Ordered Quantity: ${data.line_items[item].quantity} <a class="open_product" href="https://saved-by-the-dress.myshopify.com/admin/products/${data.line_items[item].product_id}" target="_blank">(Open)</a></div>
                <div class="sku${count}">SKU: ${data.line_items[item].currentSku}</div>
                <div class="vendor">Style: ${vendor}</div>
                <div class="inventory">Current:<span class="current-inventory">${currentInventory}</span> &nbsp Update inventory: <input class="update-inventory" type="checkbox" value="" ${checked}> &nbsp
                    <div class="quantity-show">Pick Return Quantity: <select class="quantity-select quantity-length${count}"></select></div>
                </div>
            </div>
        </div>
        `;
    };

    App.SbtdReturns.ShopifyLink = (data) => {
        return `
        <a href="https://saved-by-the-dress.myshopify.com/admin/orders/${data}" target="_blank">Open In Shopify</a>
        `;
    };
    App.SbtdReturns.QuantityShow = (data) => {
        return `
         <option value="${data}">${data}</option>
        `;
    };