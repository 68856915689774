App.User = {};

App.UserShowIndex = function() {
    var user_id = $('#user_id').val();
    $("#fileuploader").uploadFile({
        url:"/users/upload",
        fileName:"myfile",
        acceptFiles:"image/*",
        showPreview:true,
        previewHeight: "100px",
        previewWidth: "auto",
        formData: { user_id: user_id },
        onSuccess:function(files,data,xhr,pd)
        {

            //files: list of files
            //data: response from server
            //xhr : jquer xhr object
        },
        afterUploadAll:function(obj)
        {
            window.setTimeout('location.reload()', 200);
        }
    });
};

