App.FilterCollection = {};

App.ShopifyfilterCollectionsFormIndex = function() {
    //console.log('123');

    $(".form-list").on('click', ".btn_remove_item", App.FilterCollection.RemoveItem);
    $(".form-list").on('keypress', "input[type=text]", App.FilterCollection.ItemEnterKey);
    $(".btn_add_new_item").on('click', App.FilterCollection.AddNewItem);
    $(".btn_remove_all_items").on('click', App.FilterCollection.RemoveAllItems);
};

App.FilterCollection.ItemEnterKey = function (event) {
    var code = event.keyCode || event.which;
    if (code == 13) {
        App.FilterCollection.AddNewItem();
        return false;
    }
};
App.FilterCollection.RemoveAllItems = function() {
    $('.form-list').find('li.item').remove();
};
App.FilterCollection.AddNewItem = function() {
    $('.form-list').append(App.FilterCollection.InputTemplate());
    $(".form-list").find("input[type=text]:last").focus();
};
App.FilterCollection.RemoveItem = function(event) {
    $(event.target).parent('li.item').remove();
};
App.FilterCollection.InputTemplate = function() {
    return `<li class="item">
        <input type="text" name="styles[]" />
        <a href="javascript:void(0)" class="btn btn-danger btn_remove_item" title="Remove all">X</a>
    </li>`;
}

