App.ShopifyFilterShow = {};

App.ShopifyfilterProductsShowIndex = function() {
    App.ShopifyFilterShow.LoadProduct();
    App.ShopifyFilterShow.init();
    App.ShopifyFilterShow.SubmitFilters();
    App.ShopifyFilterShow.CheckColor();
};

App.ShopifyFilterShow.init = function () {
    var product_id = $('#product_id').val();
    if(product_id) {
        let url = window.location.origin + '/shopify-filter/load/' + product_id;
        $.ajax({
            type: "GET",
            url: url,
            success: App.ShopifyFilterShow.InitCallback,
            error: function(data){
                showToast(data);
            }
        })
    }
};

App.ShopifyFilterShow.InitCallback = function(response) {
    var product = response.data.product;
    var collections = response.data.collections;
    var productTags = response.data.productTags;
    if (collections.length != 0) {
        $.each(collections, function (key, value) {
            $('#collections').append(App.ShopifyFilterShow.CollectionsTemplate(value));
        });
    }else{
        //$('#filter_products_show').css('display', 'none');
        $('#collections').css('display', 'block');
        $('#collections').append('<label style="color:#FF5454">There are no Collection Styles available for this Product.</label>');
        return false;
    }
    if (productTags.length != 0){
        $.each(productTags, function(key, value) {
            $('#collections input').each(function () {
                  if ($(this).attr('data-collection-id') == value.filter_collections_id){
                      $(this).prop("checked", true);
                }
            })
        });
    }
    $('#collections').css('display', 'block');
    $('#filter_products_show').css('display', 'block');

    $('#collections input').each(function () {
        if (this.checked){
            App.ShopifyFilterShow.LoadStyles($(this).attr('data-collection-id'), product.id);
        }
    })
    App.ShopifyFilterShow.Checked();
};

App.ShopifyFilterShow.SubmitFilters = function () {
    $('#filter_products_show').click(function () {
        $('.label-massage').remove();
        var allData = [];
        var collection_id = 0;
        var product_id = $('#product_id').val();
        var shopify_product_id = $('#shopifyProductId').val();


        var colorNames = [];
        var colors = [];
        $('.row-color').each(function () {
            let el = $(this);
            if (el.find('.check').is(':checked')){
                colors.push("co-" + el.find('.check').val());
                colorNames.push(el.find('.color-label').html());
            }
        });

        var sizes = [];
        var variants = [];
        $('.variant').each(function () {
            var row = {};
            let el = $(this);
            let selectedItem = el.find('.select-size option:selected');
            row.is_size = selectedItem.val();
            row.size = selectedItem.text();
            row.position = selectedItem.attr('data-size-position');

            row.inventory = el.find("input[name*='inventory']").val();
            row.sku = el.find("input[name*='sku']").val();
            row.variant_id = el.find("input[name*='variant_id']").val();
            row.wight = el.find("input[name*='wight']").val();
            row.color = el.find("input[name*='color']").val();
            variants.push(row);
            sizes.push("si-" + row.is_size);
        });



        var styles = [];
        $('#collections input').each(function () {
            if(this.checked){
                collection_id = $(this).attr('data-collection-id');
                $('.styles' + collection_id + ' input').each(function () {
                    if(this.checked){
                        var row = {};
                        var style_id = $(this).attr('data-collection-id');
                        row.style_id = style_id;
                        row.product_id = product_id;
                        row.collection_id = collection_id;
                        allData.push(row);
                        styles.push("st-" + $(this).attr('data-style-tagname'));
                    }
                })
            }
        });


        // Validation

        if (App.ShopifyFilterShow.CheckIfUnique(sizes) == false) {
            App.Global.ToastMessage("Please pick different size for each collection !!!");
            return false;
        }

        if (colors.length < 1) {
            App.Global.ToastMessage("Please pick at least one color !!!");
            return false;
        }

        $.each(variants, function( key, value ) {
            if (value.is_size == "") {
                App.Global.ToastMessage("All varians must have size !!!");
                $.stopPropagation();
            }
            if (value.variant_id == "") {
                App.Global.ToastMessage("All varians must have price !!!");
                $.stopPropagation();
            }
        });




        var allTags = $.merge(colors, sizes);
        $.merge(allTags, styles);



        let url = window.location.origin + '/shopify-filter/update';
        //if(allData.length > 0){
            $.ajax({
                type: "POST",
                url: url,
                data: {allData:allData, product_id:product_id, shopify_product_id:shopify_product_id, allTags:allTags, variants:variants},
                success: App.SbtdReturns.RedirectToFilterProducts,
                error: function(data){
                    showToast(data);
                }
            });

        //}else{
        //    $('#collections').append('<label class="label-massage" style="color:#FF5454">Please select at least one Collection and Style!</label>');
        //}
    })
}

App.SbtdReturns.RedirectToFilterProducts = function () {

    window.location.href = window.location.origin + '/shopify-filter/products';
}

App.ShopifyFilterShow.LoadStyles = function (collection_id, product_id) {
    let url = window.location.origin + '/shopify-filter/loadStyles/' + collection_id + '/' + product_id;
    $.ajax({
        type: "GET",
        url: url,
        success: App.ShopifyFilterShow.LoadStylesCallback,
        error: function(data){
            showToast(data);
        }
    });
}

App.ShopifyFilterShow.LoadStylesCallback = function (response) {
    var productTags = response.data.productTags;
    var collectionStyles = response.data.collectionStyles;
    var collection = response.data.collection;
    $('.collect-style').clone().appendTo('.filter-holder').attr('class', 'col-lg-3 col-md-3 well styles'+collection.id);

    $.each(collectionStyles, function(key, value) {
        $('.styles' + collection.id).append(App.ShopifyFilterShow.StylesTemplate(value, collection.id));
    });

    if (productTags.length != 0){
        $.each(productTags, function(key, value) {
            var check = $('.styles' + collection.id + ' input');
            check.each(function () {
                if ($(this).attr('data-collection-id') == value.filter_collection_styles_id){
                    $(this).prop("checked", true);
                }
            })
        });
    }

    $('.styles' + collection.id + ' .style-name').html('Styles for ' + collection.name);
    $('.styles' + collection.id).css('display', 'block');
}

App.ShopifyFilterShow.StylesTemplate = (value, id) => {
    return`
    <div class="row return-entry" id="${value.id}">
        <input id="${id}${value.id}" style="float: left; margin-right:6px;" class="check" type="checkbox" value='0' data-collection-id="${value.id}" data-style-tagname="${value.tag_name}">
        <label for="${id}${value.id}" style="">${value.name}</label>
    </div>
    `;
};

App.ShopifyFilterShow.CollectionsTemplate = (value) => {
return`
    <div class="row return-entry" id="${value.id}">
        <input id="collect${value.id}" style="float: left; margin-right:6px;" class="check" type="checkbox" value='0' data-collection-id="${value.id}">
        <label for="collect${value.id}" style="">${value.name}</label>
    </div>
    `;
};

App.ShopifyFilterShow.Checked = function () {
    $('#collections input').click(function (e) {
        if (this.checked){
            var product_id = $('#product_id').val();
            App.ShopifyFilterShow.LoadStyles($(this).attr('data-collection-id'), product_id);
        }else{
            var cls = 'styles' + $(this).attr('data-collection-id');
            $('.' + cls).remove();
        }
    })
};

App.ShopifyFilterShow.CheckColor = function () {
    var tags = $("#product_tags").val();
    tags = tags.split(',');

    $('.row-color').each(function () {
        let el = $(this);
        var color = "co-" + el.find('.check').val();
        $.each(tags, function (index, tag) {
            if (color == $.trim(tag)) {
                el.find('.check').attr('checked', true);
            }
        })
    })
};

App.ShopifyFilterShow.LoadProduct = function() {
    $('#product').keyup(function(e) {
        $('#product').attr('autocomplete','off');
        if (e.keyCode == 13) {
            var selected = $('#results .selected-div');
            if(selected.length > 0){
                $product_id = selected.attr('id');
                $('#productId').val($product_id);
                $('#results').hide();
                $('.search-form').submit();
            }
        }
        if (e.keyCode == 40 || e.keyCode == 38) {
            App.ShopifyFilterShow.SelectProduct(e.keyCode);
        }else{
            $('#results').children().remove();
            $('#results').show();
            var key = {'key' : $('#product').val()};
            var URL = window.location.origin + '/products/sproduct';
            $.ajax({
                type: "GET",
                url: URL,
                data: key,
                success: App.ShopifyFilterShow.LoadProductCallback,
                error: function(data){
                    showToast(data);
                }
            });
        }
    }).delay(300);
};

App.ShopifyFilterShow.SelectProduct = function (keyCode) {
    var selected = $('#results .selected-div');
    if(selected.length == 0){
        $('#results div').first().attr('class', 'row return-entry result search-main selected-div');
    }else{
        if (keyCode == 40){
            selected.next().attr('class', 'row return-entry result search-main selected-div');
            selected.attr('class', 'row return-entry result search-main');
        }else{
            selected.prev().attr('class', 'row return-entry result search-main selected-div');
            selected.attr('class', 'row return-entry result search-main');
        }
    }
}

App.ShopifyFilterShow.LoadProductCallback = function(data) {
    $('#results').children().remove();
    $.each(data, function(key, value) {
        var title = value.title.replace(/"/g,"");
        $('#results').append(App.ShopifyFilterShow.LoadProductTemplate(value, title));
    });
    $('.result').click(function() {
        var id = $(this).attr('id');
        //var title = $(this).attr('data-title');
        //var url = $(this).attr('data-url');
        //var image = "<img class=\"img-thumbnail\" src=\"" + url + "\" style=\"height:180px\">";
        $('#product').val("");
        $('#productId').val(id);
        $('#results').hide();
        $('.search-form').submit();
    });
};

App.ShopifyFilterShow.LoadProductTemplate = (value, title) => {
return `
    <div class="row return-entry result search-main" data-url="${value.images}" data-title="${title}" id="${value.id}">
        <img class="img-thumbnail img-search" src="${value.images}" style="height:75px">
        <div style="display:inline-block; padding-left:10px; position:absolute; margin-top:12px;">
            <div> Title: ${value.title}</div>
            <div> Sku: ${value.sku}</div>
        </div>
    </div>
`;
};

App.ShopifyFilterShow.CheckIfUnique = function (myArray) {
    for (var i = 0; i < myArray.length; i++)
    {
        if (myArray.indexOf(myArray[i]) !== myArray.lastIndexOf(myArray[i])) {
            return false;
        }
    }
    return true;
};