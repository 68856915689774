    App.SbtdReturnsProcess = {};
    App.SbtdReturnsProcess.orderNumber = '';
    App.SbtdReturnsProcess.returnId = '';
    App.SbtdReturnsProcess.orderData = '';
    App.SbtdReturnsProcess.returnOrderData = '';

    App.ReturnsReturnFormIndex = function() {
        //App.SbtdReturns.LoadOrder();
        //App.SbtdReturns.FirstLoad();

        App.SbtdReturnsProcess.orderNumber = orderNumber;
        App.SbtdReturnsProcess.returnId = returnId;

        if (orderNumber != '') {
            App.SbtdReturnsProcess.orderData = orderData;
        }

        if (returnId > 0) {
            App.SbtdReturnsProcess.returnOrderData = returnOrder;
        }

        App.SbtdReturnsProcess.bindEvents();

    };

    App.SbtdReturnsProcess.getTransactions = function() {
        url = window.location.origin + '/returns/process/transactions';
        $.ajax({
            type: "GET",
            url: url,
            data: {number: orderNumber},
            success: App.SbtdReturnsProcess.getTransactionsSuccess,
            error: function(data){
                console.log(data);
                //$(".error-massage").html("Error! Order not found");
            }
        });
    };

    App.SbtdReturnsProcess.closeTransactionsPopup = function() {
        $('#transactionsPopup').addClass('hidden');
    };

    App.SbtdReturnsProcess.getTransactionsSuccess = function(response) {
        console.log(response);
        $('#transactionsPopup').removeClass('hidden');
        const refunds = [];
        const sales = [];
        let totalRefundableCC = 0;
        let totalRefundableGC = 0;
        let refundCC = 0;
        let refundGC = 0;

        const allTransactions = response.data.transactions;
        for (const key of Object.keys(allTransactions)) {
            const transaction = allTransactions[key];
            const amnt = Math.round(transaction.amount);
            if (transaction.status !== 'success') {
                continue;
            }

            if (transaction.kind === 'refund') {
                refunds.push(amnt);

                if (transaction.gateway !== 'gift_card' && transaction.gateway !== 'wanelo') {
                    totalRefundableCC -= amnt;
                } else if (transaction.gateway !== 'wanelo') {
                    totalRefundableGC -= amnt;
                }

            } else if (transaction.kind === 'sale') {
                sales.push(amnt);

                if (transaction.gateway !== 'gift_card' && transaction.gateway !== 'wanelo') {
                    totalRefundableCC += amnt;
                } else if (transaction.gateway !== 'wanelo') {
                    totalRefundableGC += Math.round(amnt);
                }
            }
        }

        const amount = $('#amount').val();
        if (amount <= totalRefundableCC) {
            refundCC = amount;
            refundGC = 0;
        } else {
            refundCC = totalRefundableCC;
            refundGC = amount - refundCC;
            if (refundGC > totalRefundableGC) {
                refundGC = totalRefundableGC;
            }
        }
        const popupEl = $('#transactionsPopup');

        popupEl.find('.max-cc-amount').html(totalRefundableCC);
        popupEl.find('.max-gc-amount').html(totalRefundableGC);

        popupEl.find('.transaction-cc-input').val(refundCC);
        popupEl.find('.transaction-gc-input').val(refundGC);

        const oldOrdersEl = popupEl.find('.old-orders');

        if (response.data.oldOrdersLink !== '') {
            oldOrdersEl.removeClass('hidden');
            oldOrdersEl.find('.old-orders-link').href = response.data.oldOrdersLink;
        } else {
            oldOrdersEl.addClass('hidden');
        }

        App.SbtdReturnsProcess.calcTotalRefundAmount();

    };

    App.SbtdReturnsProcess.calcTotalRefundAmount = function() {
        const popupEl = $('#transactionsPopup');

        const totalRefundableCC = Number(popupEl.find('.max-cc-amount').html());
        const totalRefundableGC = Number(popupEl.find('.max-gc-amount').html());

        const refundCC = Number(popupEl.find('.transaction-cc-input').val());
        const refundGC = Number(popupEl.find('.transaction-gc-input').val());

        if (refundCC < 0 || isNaN(refundCC)) {
            popupEl.find('.transaction-cc-input').val(0);
        }
        if (refundGC < 0 || isNaN(refundGC)) {
            popupEl.find('.transaction-gc-input').val(0);
        }

        if (refundCC > totalRefundableCC) {
            popupEl.find('.transaction-cc-input').val(totalRefundableCC);
        }
        if (refundGC > totalRefundableGC) {
            popupEl.find('.transaction-gc-input').val(totalRefundableGC);
        }

        popupEl.find('.total-amount').html(refundCC + refundGC);

    };

    App.SbtdReturnsProcess.bindEvents = function () {
        $('.check').click(function () {
            App.SbtdReturnsProcess.setCheckBoxUI($(this));
        });

        $('.check').each(function() {
            App.SbtdReturnsProcess.setCheckBoxUI($(this));
        });

        $('.item-image').click(function () {
            $(this).parent().find(".check").click();
        });

        $('.quantity-select').on('change', function (e) {
            App.SbtdReturnsProcess.Amount(App.SbtdReturnsProcess.orderData);
            return false;
        });

        if (App.SbtdReturnsProcess.returnOrderData == '' && App.SbtdReturnsProcess.orderData != '') {

            $("input:radio[value='everything_ok']").prop('checked', true);

            var t = App.SbtdReturnsProcess.orderData.order_date.split(/[- :]/);
            var orderDate = new Date(Date.UTC(t[0], t[1]-1, t[2], t[3], t[4], t[5]));

            var now = new Date();

            var one_day=1000*60*60*24;    // Convert both dates to milliseconds
            var date1_ms = orderDate.getTime();
            var date2_ms = now.getTime();    // Calculate the difference in milliseconds
            var difference_ms = date2_ms - date1_ms;        // Convert back to days and return
            let days = Math.round(difference_ms/one_day);

            if (days >45) {
                $("input:radio[value='over_30_days']").prop('checked', true);
            } else if (days <= 32) {
                //$("#extra_amount_5").prop('checked', true);
                $("#return_type_gift_card").prop('checked', true);
            }


        }

        $("input:radio[name ='email_template']").on('click', function() {
            if (this.value == 'sent_cleaning') {

                $('.update-inventory').prop('checked', false);
                $('.update-inventory').prop('disabled', true);
            } else {
                $('.update-inventory').prop('disabled', false);
            }
        });

        $('#return_type_refund').on('click', function() {
            $('#extra_amount_0').prop('checked', true);
        });

        $('#send_order_items').on('click', App.SbtdReturnsProcess.ajaxSubmitApprove.bind(this));
        $('#send_order_items_update_details').on('click', App.SbtdReturnsProcess.ajaxSubmitUpdate.bind(this));
        $('#send_order_items_update').on('click', App.SbtdReturnsProcess.ajaxSubmitUpdate.bind(this));
        $('#cancel_return').on('click', App.SbtdReturnsProcess.ajaxSubmitCancel.bind(this));

        $('#transactionsPopup').find('.x-close').on('click', App.SbtdReturnsProcess.closeTransactionsPopup.bind(this));

        $('#transactionsPopup').find('.transaction-cc-input').on('keyup', App.SbtdReturnsProcess.calcTotalRefundAmount.bind(this));
        $('#transactionsPopup').find('.transaction-gc-input').on('keyup', App.SbtdReturnsProcess.calcTotalRefundAmount.bind(this));

    };


    App.SbtdReturnsProcess.setCheckBoxUI = function(checkbox) {
        var parentcheck = checkbox.parent();
        if (checkbox.prop('checked')) {
            parentcheck.find(".quantity-show").css("display", "block");
            parentcheck.find(".inventory").css("display", "block");

            parentcheck.find('.newSku').removeClass('hide');

            App.SbtdReturnsProcess.Amount(App.SbtdReturnsProcess.orderData);

            if (parentcheck.is(':nth-child(2n+1)') == true) {
                parentcheck.css("background-color", "#96d796");
            } else {
                parentcheck.css("background-color", "rgb(127, 174, 127)");
            }
        } else {
            parentcheck.find(".quantity-show").css("display", "none");
            parentcheck.find(".inventory").css("display", "none");

            parentcheck.find('.newSku').addClass('hide');

            App.SbtdReturnsProcess.Amount(App.SbtdReturnsProcess.orderData);

            if (parentcheck.is(':nth-child(2n+1)') == true) {
                parentcheck.css("background-color", "#eee");
            } else {
                parentcheck.css("background-color", "#fff");
            }
        }
    };

    App.SbtdReturnsProcess.Amount = function (order) {
        var prods = [];
        var totalDiscount = order.total_discounts;
        var totalTax = order.total_tax;
        var totalAmount = 0;
        var totalPrice = 0;

        $('.check').each(function () {
            var checkbox = $(this).parent();
            if (this.checked) {
                var price = checkbox.find(".get-price").attr("data-price");
                var quantity = checkbox.find(".quantity-select").val();
                totalAmount = totalAmount + (price * quantity);
            }
        });

        for (var v in order.line_items) {
            var itm = order.line_items[v];
            totalPrice = totalPrice + (itm.price * itm.quantity);
        }



        if (totalDiscount > 0 && totalAmount > 0) {
            discount_amount_multiplier = totalAmount / totalPrice;
            discount_amount = totalDiscount * discount_amount_multiplier;
            discount_amount = parseFloat(discount_amount.toFixed(2));
            totalAmount = totalAmount - discount_amount;
        }
        if (totalTax > 0 && totalAmount > 0) {
            discount_amount_multiplier = totalAmount / totalPrice;
            discount_amount = totalTax * discount_amount_multiplier;
            discount_amount = parseFloat(discount_amount.toFixed(2));
            totalAmount = totalAmount + discount_amount;
        }

        $("#amount").val(totalAmount);
    };


    App.SbtdReturnsProcess.collectFormData = function() {
        var data = {
            returnId: App.SbtdReturnsProcess.returnId,
            orderNumber: App.SbtdReturnsProcess.orderNumber,
            isRefund: $('#return_type_refund').prop('checked') == true ? "1" : "0",
            emailTemplate: ($("input:radio[name ='email_template']:checked").val()) ? $("input:radio[name ='email_template']:checked").val() : "",
            amount: $("#amount").val(),
            extraAmount: ($("input:radio[name ='extra_amount']:checked").val()),
            note: $("#note").val(),
        };

        data.items = [];
        $('.check:checked').each(function () {
            var checkbox = $(this);
            item = {};
            if (this.checked) {
                item.name = checkbox.parent().find('.prod-name-only').html();
                item.image = checkbox.parent().find('.item-image').attr('src');
                item.price = checkbox.parent().find('.get-price').attr('data-price');
                item.productId = checkbox.attr("data-product-id");
                item.id = checkbox.attr("data-item-id");
                item.quantity = checkbox.parent().find(".quantity-select").val();
                item.inventory = checkbox.parent().find(".update-inventory").prop('checked') ? true : "";
                item.variantId = checkbox.parent().find(".variant").val();
                item.oldSku = checkbox.parent().find('.oldSku').html();
                item.checkSku = item.inventory && checkbox.parent().find(".sku_input").length > 0;
                item.newSku = '';
                if (item.checkSku) {
                    item.newSku = checkbox.parent().find(".sku_input").val();
                }
            }
            if ($.isEmptyObject(item) == false){
                data.items.push(item);
            }
        });
        return data;
    };

    App.SbtdReturnsProcess.validateFormData = function(data) {
        if (data.returnId == '' && data.orderNumber == '') {
            App.Global.ToastMessage("Order Data not supplied");
            return false;
        }
        if (data.items.length == 0) {
            App.Global.ToastMessage("Please pick at least 1 item");
            return false;
        }

        if (data.emailTemplate == '') {
            App.Global.ToastMessage("Please pick email template");
            return false;
        }

        var itemsGood = true;
        $.each(data.items, function(index) {
            let item = data.items[index];
            if (item.checkSku == true) {
                var skuValue = item.newSku;
                var firstChar = skuValue.charAt(0);
                var numberPart = parseInt(skuValue.substring(1));
                var msg = '';
                if (firstChar != 'R' ) {
                    msg = 'SKU has to start with R, item: ' + item.oldSKu;
                    App.Global.ToastMessage(msg);
                    itemsGood = false;
                }
                else if (isNaN(numberPart) || numberPart < 1 || numberPart > 500) {
                    msg = 'SKU has to be between 1 and 500 (R123),' + item.oldSKu;
                    App.Global.ToastMessage(msg);
                    itemsGood = false;
                }
            }
        });
        return itemsGood;
    };

    App.SbtdReturnsProcess.ajaxSubmitApprove = function() {
        var data = this.collectFormData();

        var dataValid = this.validateFormData(data);

        if (!dataValid) {
            return;
        }

        url = window.location.origin + '/returns/process/approve';
        $.ajax({
            type: "POST",
            url: url,
            data: data,
            success: App.SbtdReturnsProcess.ajaxSubmitSuccess,
            error: function(data){
                console.log(data);
                //$(".error-massage").html("Error! Order not found");
            }
        });

    };

    App.SbtdReturnsProcess.ajaxSubmitUpdate = function() {
        var data = this.collectFormData();

        var dataValid = this.validateFormData(data);

        if (!dataValid) {
            return;
        }

        url = window.location.origin + '/returns/process/update';
        $.ajax({
            type: "POST",
            url: url,
            data: data,
            success: App.SbtdReturnsProcess.ajaxSubmitSuccess,
            error: function(data){
                console.log(data);
                //$(".error-massage").html("Error! Order not found");
            }
        });

    };

    App.SbtdReturnsProcess.ajaxSubmitCancel = function() {
        var orderId = App.SbtdReturnsProcess.returnId;
        url = window.location.origin + '/returns/process/cancel-return';
        $.ajax({
            type: "GET",
            url: url,
            data: {order_id:orderId},
            success: App.SbtdReturnsProcess.RedirectToList,
            error: function(data){
                console.log(data);
                //$(".error-massage").html("Error! Order not found");
            }
        });
    };

    App.SbtdReturnsProcess.ajaxSubmitSuccess = function(response) {

        if (response.errors == false) {
            App.SbtdReturns.RedirectToList();
        } else {
            App.Global.ToastMessage(response.data.message);
        }
    };


    App.SbtdReturnsProcess.RedirectToList = function () {
        window.location.href = window.location.origin + '/returns/sbtd_return_list';
    };
