App.Packer = {};

App.PackerHomeIndex = function() {
    App.Packer.Barcode();
    App.Packer.LoadPackerInit();
    //App.Packer.StopCountTime();
    $('#isOrder').val('');

    App.Packer.HidePopup();
    App.Packer.ChangePacker();
};

App.Packer.barcodeAction = false;
App.Packer.barcodeOutput = '';

App.Packer.LoadPackerInit = function() {
    url = window.location.origin + '/packers/packersOrder';
    $.ajax({
        type: "GET",
        url: url,
        success: App.Packer.LoadPackerOrderCallback,
        error: function(data){
            console.log(data);
        }
    });
};


App.Packer.LoadPackerOrderCallback = function(result) {
    console.log(result);
    var user = result.data.user;
    var order = result.data.currentShipment;
    var items = result.data.currentShipment.items;
    var closed = result.data.closedShipments;

    var newOrder = App.Packer.FixOrderShippingType(order);
    order = newOrder;

    $('.welcome').html('Welcome ' + user.name);
    $('#code').val('');
    $('#results').children().remove();
    $('#itemResults').children().remove();
    $('#orderResults').children().remove();
    if(order.tracking_number) {
        $('#isOrder').val(order.id);
        $('.empty').css('display', 'block');
    }else{
        $('#isOrder').val('');
        $('.empty').css('display', 'none');
    }
    // Order
    $('#results').append(App.Packer.LoadPackerOrderTemplate(order));

    // Order Items
    $.each(items, function(key, value) {
        var color = "";
        if (value.status == 2){
            color = "green";
        }
        if (value.status == 3){
            color = "red";
        }
        if (value.error_id){
            var clas = "btn btn-danger quality";
            var dataId = "data-error-id=\"" + value.error_id + "\"";
            var name = "Bad Quality";
        }else{
            var clas = "btn btn-info quality";
            var dataId = "data-error-id=\"\"";
            var name = "Quality Control";
        }

        var nameSplitted = value.shipstation_item.name.split(' - ');
        var sizeStr = nameSplitted[nameSplitted.length-1];
        var sizeSplitted = sizeStr.split(' / ');
        value.size = sizeFinal = sizeSplitted[0];
        value.shipmentItemName = nameSplitted[0];
        $('#itemResults').append(App.Packer.LoadPackerOrderItemsTemplate(value, color, clas, dataId, name));

    });

    //add manual select
    $('#itemResults').find('.oneOrderItem').each(function() {
       if (!$(this).hasClass('red') || !$(this).hasClass('green')) {
           $(this).on('click', App.Packer.ManualProcessItem.bind(this, $(this).attr('data-id')));
       }
    });

    // Closed Orders
    if (closed.length > 0){
        $('.incomplite').css('display', 'block');
    }else{
        $('.incomplite').css('display', 'none');
    }
    var count = 1;
    $.each(result.data.closedShipments, function(key, value) {
        $('#orderResults').append(App.Packer.ClosedOrderTemplate(count, value));
        count++;
    });
    $('.image').attr('src', user.image);
    App.Packer.EditOrder();
    App.Packer.SuspendOrder();
    App.Packer.QualityControl();
    App.Packer.UpdateSpeed();
};


App.Packer.FixOrderShippingType = function (order) {
    if (order.shipping_type != null) {

         var shipingTypeArray = order.shipping_type.split('_');
         var newType = "";

         $.each(shipingTypeArray, function( key, value ) {
         var firstUpperCase = value.substring( 0, 1 ).toUpperCase() + value.substring( 1 );
         if (key == 0) {
         firstUpperCase = firstUpperCase + " -";
         }
         if (firstUpperCase != "Mail") {
         newType += " " + firstUpperCase;
         }
         });
         order.shipping_type = newType;

    } else {
        order.shipping_type = "";
    }

    return order;

}

App.Packer.ManualProcessItem = function(itemId) {
    url = window.location.origin + '/packers/packersOrderItem';
    url = window.location.origin + '/packer/OrderItem/' + itemId + '/' + $('#isOrder').val() + '/1';
    $.ajax({
        type: "GET",
        url: url,
        data: {item_id: itemId},
        success: App.Packer.LoadPackerOrderCallback,
        error: function(data){
            console.log(data);
        }
    });
};

App.Packer.LoadPackerOrderTemplate = (order) => {
    return `
<div class="row return-entry">
    <div class="row-data">
        <div class="col-lg-2 col-md-2">
            <a id="${order.id}" class="btn btn-warning cancel" title="Cancel order">Cancel Order</a>
        </div>
        <div class="col-lg-4 col-md-4">${order.tracking_number}</div>
        <div class="col-lg-2 col-md-2">${order.number}</div>
        <div class="col-lg-2 col-md-2">${order.shipping_type}</div>
        <div class="col-lg-2 col-md-2 actions">
            <a id="${order.id}" class="btn btn-danger suspended" title="Suspended">Force Close Order</a>
        </div>
    </div>
</div>
`;
};


App.Packer.LoadPackerOrderItemsTemplate = (value, color, clas, dataId, name) => {
    return `
<div class="row col-lg-3 col-md-3 col-sm-4">
    <div class="oneOrderItem row-data ${color}" data-id="${value.id}">
        <div class="">
            <img src="${value.shipstation_item.image_url}" height="180" width="120">
        </div>
        <div class="data-size">${value.size}</div>
        <div class="data-sku">${value.shipmentItemName}</div>       
        <!--
        <div class="">
            <a id="${value.barcode}" ${dataId} class="${clas}" title="Quality">${name}</a>
        </div>
        -->
    </div>
</div>
`;
};


App.Packer.ClosedOrderTemplate = (count, value) => {
    return `
<div class="row return-entry">
    <div class="row-data">
        <div class="col-lg-1 col-md-2">${count}</div>
        <div class="col-lg-5 col-md-2">${value.tracking_number}</div>
        <div class="col-lg-5 col-md-2">${value.number}</div>
        <div class="col-lg-offset-1 col-md-offset-1 actions">
            <a id="${value.tracking_number}" class="btn btn-warning edit" title="Edit">Edit</a>
        </div>
    </div>
</div>
`;
};


App.Packer.LoadBarcode = function() {
    if (App.Packer.barcodeAction) {
        if ($('#isOrder').val() === ''){
            App.url = window.location.origin + '/packer/Order/' + $('#code').val();
        }else{
            App.url = window.location.origin + '/packer/OrderItem/' + $('#code').val() + '/' + $('#isOrder').val();
        }
        $.ajax({
            type: "GET",
            url: App.url,
            success: App.Packer.LoadPackerOrderCallback,
            error: function(){
                $('#code').val('');
            }
        });
        App.Packer.barcodeAction = false; //reset type listener
    }
};

App.Packer.Barcode = function () {
    $(document).on({
        keypress: function (e) {
            var code = e.keyCode || e.which;
            App.Packer.barcodeOutput +=  String.fromCharCode(code);
            if (code == 13) {
                App.Packer.barcodeAction = true;
                $('#code').val(App.Packer.barcodeOutput);
                App.Packer.barcodeOutput = "";
                App.Packer.LoadBarcode();
            }
        }
    });
}

App.Packer.EditOrder = function () {
    $('.edit').click(function(e){
        if($('#isOrder').val() === '') {
            var barcode = $(this).attr('id');
            url = window.location.origin + '/packer/Order/' + barcode;
            $.ajax({
                type: "GET",
                url: url,
                success: App.Packer.LoadPackerOrderCallback,
                error: function(data){
                    console.log(data);
                }
            });
        }else{
            App.Global.ToastMessage('Finish or Force close started order first!');
        }
    });
};

App.Packer.SuspendOrder = function () {
    $('.suspended').click(function(e){
        var order_id = $(this).attr('id');
        url = window.location.origin + '/users/suspendOrder/' + order_id;
        $.ajax({
            type: "GET",
            url: url,
            success: App.Packer.LoadPackerOrderCallback,
            error: function(data){
                console.log(data);
            }
        });
    });

    $('.cancel').click(function(e){
        var order_id = $(this).attr('id');
        url = window.location.origin + '/users/deleteOrder/' + order_id;
        $.ajax({
            type: "GET",
            url: url,
            success: App.Packer.LoadPackerOrderCallback,
            error: function(data){
                console.log(data);
            }
        });
    });



};

App.Packer.QualityControl = function () {
    $('.quality').click(function(e){
        var order_id = $('#isOrder').val();
        var barcode = $(this).attr('id');
        var name = $(this).html();
        var error_id = $(this).attr('data-error-id');
        if(name == "Quality Control") {
            $(this).html('Bad Quality');
            $(this).attr('class', 'btn btn-danger quality');
            url = window.location.origin + '/product/logError/' + order_id + '/' + barcode;
        }else{
            $(this).html('Quality Control');
            $(this).attr('class', 'btn btn-info quality');
            url = window.location.origin + '/product/deleteError/' + error_id;
        }
        $.ajax({
            type: "GET",
            url: url,
            success: App.Packer.QualityControlCallback.bind(this),
            error: function(data){
                console.log(data);
            }
        });
    });
};

App.Packer.QualityControlCallback = function (result) {
    if(result.data.isLogError == true){
        $(this).attr('data-error-id', result.data.itemError.id);
    }else{
        $(this).attr('data-error-id', '');
    }
    $('#code').val('');
};

App.Packer.UpdateSpeed = function () {
    url = window.location.origin + '/packers/live-speed';
    $.ajax({
        type: "GET",
        url: url,
        success: App.Packer.UpdateSpeedCallback,
        error: function(data){
            console.log(data);
        }
    });
};

App.Packer.UpdateSpeedCallback = function (result) {
    var today_speed = result.data.speed.today == null ? 0 : result.data.speed.today;
    var total_speed = result.data.speed.total == null ? 0 : result.data.speed.total;
    var total_items = result.data.speed.items == null ? 0 : result.data.speed.items;
    $('#packer_speed').find('.today_speed').html(today_speed);
    $('#packer_speed').find('.total_speed').html(total_speed);
    $('#packer_speed').find('.total_items_speed').html(total_items);
};


App.Packer.CheckPin = function () {
    $(document).off('keypress');
    $('#pincode').keypress(function (e) {
        e.stopPropagation();
        var key = e.keyCode || e.which;
        if(key == 13)  // the enter key code
        {
            App.Packer.LoadPacker();
        }

    });
}

App.Packer.LoadPacker = function() {
    url = window.location.origin + '/users/order/' + $('#pincode').val();
    $.ajax({
        type: "GET",
        url: url,
        success: function() {
            window.location.reload();
        },
        error: function(data){
            console.log(data);
            $('#pincode').val('');
            $('.error-pin').html('Pin code not recognized');
        }
    });
}

App.Packer.ChangePacker = function () {
    $('.btn.change-packer').click(function(){
        App.Packer.ShowPopup();
    });
}
App.Packer.ShowPopup = function() {
    $('#pincode').val('');
    $('.error-pin').html('');
    $('.pin-popup').css('display', 'block');
    $('.panel.panel-default').css('display', 'none');

    App.Packer.CheckPin();

    setTimeout(function(){
        $('#pincode').focus();
    }, 1);
};
App.Packer.HidePopup = function() {
    $('.pin-popup').css('display', 'none');
    $('.panel.panel-default').css('display', 'block');
};


/*App.Packer.StopCountTime = function () {
    $('.stop-count').click(function(){
        var title = $('.stop-count').html();
        if(title == 'Stop Count Time'){
            $('.overlay').css('display', 'block');
            $('.stop-count').html('Start Count Time');
            $('.stop-count').css('background-color', '#dd0303');
            $('.stop-count').css('border', 'none');
        }else{
            $('.overlay').css('display', 'none');
            $('.stop-count').html('Stop Count Time');
            $('.stop-count').css('background-color', '#5bc0de');
        }

    });
}*/


