window.$ = window.jQuery;

var App = {};





App.PinUser = {};



App.PinUser.ClosePopup = function () {
    $('.x-close').on('click', function () {
        App.PinUser.HidePopup();
    })
};


App.PinUser.LoadPicker = function() {
    url = window.location.origin + '/users/order/' + $('#pincode').val();
    $.ajax({
        type: "GET",
        url: url,
        success: App.PinUser.LoadPickerOrderCallback,
        error: function(data){
            console.log(data);
            $('#pincode').val('');
            $('.error-pin-user').html('Pin code not recognized');
        }
    });
};

App.PinUser.LoadPickerOrderCallback = function(result) {
    $('.error-pin-user').html('');
    $('.welcome').html('Welcome ' + result.data.user.name);
    $('#code').val('');

    App.PinUser.HidePopup();

};


App.PinUser.CheckPin = function () {
    $('#pincode').keypress(function (e) {
        e.stopPropagation();
        var key = e.keyCode || e.which;
        if(key == 13)  // the enter key code
        {
            App.PinUser.LoadPicker();
        }

    });
};

App.PinUser.ChangePicker = function () {
    $('.btn.change-user').click(function(){
        if ($('.pin-popup-user').css('display') == 'none') {
            App.PinUser.ShowPopup();
        }else{
            App.PinUser.HidePopup();
        }
    });
};
App.PinUser.ShowPopup = function() {
    $('#pincode').val('');
    $('.error-pin-user').html('');
    $('.pin-popup-user').css('display', 'block');
    $('.panel.panel-default').css('display', 'none');

    setTimeout(function(){
        $('#pincode').focus();
    }, 1);
};
App.PinUser.HidePopup = function() {
    $('.pin-popup-user').css('display', 'none');
    $('.panel.panel-default').css('display', 'block');
};





App.Global = {};

App.Global.SecondsPassed = 0;


App.Global.KeepAlive = function() {
    App.Global.SecondsPassed++;

    if (App.Global.SecondsPassed == 180) {
        var url = window.location.origin + '/user-keep-alive';
        $.ajax({
            type: "GET",
            url: url
        });
    }

};
App.Global.ToastMessage = function (str, manualClose, id) {
    if (typeof(id) == 'undefined') {
        id = 'toastMessage';
    }
    id = '#' + id;
    $(id).find('.toast-content').html(decodeURIComponent(str.replace(/\+/g, ' ')));


    if (typeof(manualClose) != 'undefined') {

        $(id).fadeIn();
        $(id).find('.closeBtn').css('display', 'block');
        $(id).find('.closeBtn').off('click');
        $(id).find('.closeBtn').on('click', function() {
            $(id).fadeOut('slow');
        })
    } else {
        $(id).find('.closeBtn').css('display', 'none');
        $(id).find('.closeBtn').off('click');
        $(id).fadeIn('slow', function () {
            $(id).delay(2500).fadeOut('slow');
        });
    }
};

App.Global.SetAudioEvent = function() {
    if (App.Global.ErrorAudio.src !=  window.location.origin +  '/glass_ping.mp3') {

        var nopromise = {
            catch : new Function()
        };
        (App.Global.ErrorAudio.play() || nopromise).catch(function(){});
        App.Global.ErrorAudio.src = '/glass_ping.mp3';

    }
};
App.Global.GetUrlParameter = function (sParam) {
    var sPageURL = decodeURIComponent(window.location.search.substring(1)),
        sURLVariables = sPageURL.split('&'),
        sParameterName,
        i;

    for (i = 0; i < sURLVariables.length; i++) {
        sParameterName = sURLVariables[i].split('=');

        if (sParameterName[0] === sParam) {
            return sParameterName[1] === undefined ? true : sParameterName[1];
        }
    }
    return "";
};

App.Global.AjaxStart = function() {
    $("#loading").show();
};

App.Global.AjaxStop = function() {
    $( "#loading" ).hide();
    App.Global.SecondsPassed = 0;
};

App.Global.ErrorAudio = new Audio();
App.Global.AjaxError = function( event, jqxhr, settings ) {
    /*
    console.log("EVENT OBJECT:");
    console.log(event);
    console.log("DATA OBJECT:");
    console.log(jqxhr);
    console.log("SETTINGS OBJECT:");
    console.log(settings);
    */
    App.Global.ErrorAudio.play();

    if (typeof(jqxhr.responseJSON) != 'undefined' && typeof(jqxhr.responseJSON.errors) != 'undefined') {
        App.Global.ToastMessage(jqxhr.responseJSON.errors.message.join("<br />"), true);
    }

};

jQuery(document).ready(function($) {
    $('.delete').click(function(){
        return confirm("Do you want to delete this item?");

    });
    var toastParam = App.Global.GetUrlParameter('toast_message');
    if (toastParam != '') {
        App.Global.ToastMessage(toastParam);
    }
    var fn = App[theViewName];
    if(typeof fn === 'function') {
        fn();
    }
    if (userIsLoggedIn == true) {
        setInterval(App.Global.KeepAlive, 1000);
    }

    App.PinUser.ClosePopup();
    App.PinUser.CheckPin();
    App.PinUser.ChangePicker();



});

jQuery(document).keypress(App.Global.SetAudioEvent);

jQuery(document).ajaxError(App.Global.AjaxError);

jQuery(document).ajaxStart(App.Global.AjaxStart);

jQuery(document).ajaxStop(App.Global.AjaxStop);

