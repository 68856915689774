App.Shopify = {};

App.Shopify.isCollectionDresses = false;
App.Shopify.productCollectionsArray = [];
App.Shopify.isCollectionGroupOneOk = false;
App.Shopify.isCollectionGroupTwoOk = false;

App.ShopifyProductsNewIndex = function() {

    App.Shopify.initProduct();
    App.Shopify.RemoveTags();
    App.Shopify.AddNewTag();
    App.Shopify.AddVariants();
    App.Shopify.RemoveVariants();
    App.Shopify.CheckForSubmit();
    App.Shopify.MakeHandle();
    App.Shopify.CheckColor();
    App.Shopify.CollectionsClick();

    $('.publish_date').datepicker({});

};

App.Shopify.changeCollections = function() {
    $(".product-collections").html(App.Shopify.productCollectionsArray.join(', '));
    $("#product_type").val(App.Shopify.productCollectionsArray.join(', '));
}

App.Shopify.makeNewCollectionArray = function(collection, remCollection, collectionId, remCollectionId) {

    App.Shopify.productCollectionsArray.push(collection);
    if($.inArray(remCollection, App.Shopify.productCollectionsArray) !== -1) {
        App.Shopify.productCollectionsArray.splice($.inArray(remCollection, App.Shopify.productCollectionsArray), 1);
    }
    $("#"+collectionId).prop('checked', true);
    $("#"+remCollectionId).prop('checked', false);

    App.Shopify.changeCollections();
}

App.Shopify.CollectionsClick = function() {
    $(".base-collection-holder input").click(function (e) {
        if (this.checked){
            var collection = $(this).val();
            var remCollection = "";
            var collectionId = "";
            var remCollectionId = "";

            switch (collection) {
                case "Formal Dresses":
                    remCollection = "Casual Dresses";
                    collectionId = "15133";
                    remCollectionId = "14124";
                    break;
                case "Casual Dresses":
                    remCollection = "Formal Dresses";
                    collectionId = "14124";
                    remCollectionId = "15133";
                    break;
                case "Maxi Dresses":
                    remCollection = "Short Dresses";
                    collectionId = "14121";
                    remCollectionId = "14123";
                    break;
                case "Short Dresses":
                    remCollection = "Maxi Dresses";
                    collectionId = "14123";
                    remCollectionId = "14121";
                    break;
            }
        }
        App.Shopify.makeNewCollectionArray(collection, remCollection, collectionId, remCollectionId);
        App.Shopify.CheckCollections();
    })
}

App.Shopify.initChangeCollections = function() {

    if(App.Shopify.isCollectionDresses == true) {
        $.each(App.Shopify.productCollectionsArray, function (key, value) {
            if(value == "Casual Dresses") {
                $("#casual").attr("checked", true);
                $("#14124").prop("checked", true);
                $("#15133").prop("checked", false);
                if($.inArray("Formal Dresses", App.Shopify.productCollectionsArray) !== -1) {
                    App.Shopify.productCollectionsArray.splice($.inArray("Formal Dresses", App.Shopify.productCollectionsArray), 1);
                }
            }
        })
        $.each(App.Shopify.productCollectionsArray, function (key, value) {
            if(value == "Formal Dresses") {
                $("#formal").attr("checked", true);
                $("#14124").prop("checked", false);
                $("#15133").prop("checked", true);
                if($.inArray("Casual Dresses", App.Shopify.productCollectionsArray) !== -1) {
                    App.Shopify.productCollectionsArray.splice($.inArray("Casual Dresses", App.Shopify.productCollectionsArray), 1);
                }
            }
        })
        $.each(App.Shopify.productCollectionsArray, function (key, value) {
            if(value == "Maxi Dresses") {
                $("#maxi").attr("checked", true);
                $("#14121").prop("checked", true);
                $("#14123").prop("checked", false);
                if($.inArray("Short Dresses", App.Shopify.productCollectionsArray) !== -1) {
                    App.Shopify.productCollectionsArray.splice($.inArray("Short Dresses", App.Shopify.productCollectionsArray), 1);
                }
            }
        })
        $.each(App.Shopify.productCollectionsArray, function (key, value) {
            if(value == "Short Dresses") {
                $("#short").attr("checked", true);
                $("#14121").prop("checked", false);
                $("#14123").prop("checked", true);
                if($.inArray("Maxi Dresses", App.Shopify.productCollectionsArray) !== -1) {
                    App.Shopify.productCollectionsArray.splice($.inArray("Maxi Dresses", App.Shopify.productCollectionsArray), 1);
                }
            }
        })

        App.Shopify.changeCollections();
        App.Shopify.CheckCollections();
    }
}

App.Shopify.RemoveTags = function () {
    $('.filter-product-tag-delete').on('click', function () {
        $(this).parent().remove();
    })
};

App.Shopify.AddNewTag = function () {
    $('.add-new-tag').on('click', function () {
        if ($('.new_tag').css('display') == 'none') {
            $('.new_tag').css('display', 'block');
        } else {
            $('.new_tag').css('display', 'none');
        }
    })
    $('.new_tag').on('keyup', function (e) {
        if (e.which == 13) {
            var new_tag =  $('.new_tag').val();
            if (new_tag == "") {
                App.Global.ToastMessage("Tag cannot be empty !!!");
                return false;
            }
            var obj = $('.filter-product-tag-holder').last().clone();
            obj.appendTo('.tags');
            $('.filter-product-tag').last().html(new_tag);
            $('.new_tag').val("");
            App.Shopify.RemoveTags();
        }
    })
};

App.Shopify.AddVariants = function () {
    $('.add-new-variant').on('click', function () {
        var variantEntry = $('.row.return-entry.variant').last().clone();
        variantEntry.appendTo('.variant-holder');

        $('.variant-holder .variant').last().find('.select-size option:selected').removeAttr("selected");
        $('.variant-holder .variant').last().find('.select-size option[value=""]').attr('selected', 'selected');

        App.Shopify.RemoveVariants();
    })
};

App.Shopify.RemoveVariants = function () {
    $('.btn.btn-danger').on('click', function () {
        if ($('.variant').length > 1) {
            $(this).parent().parent().remove();
        } else {
            App.Global.ToastMessage("Canot delete last Variant");
        }
    })
};

App.Shopify.MakeHandle = function () {
    $('#name').on('keyup', function () {
       var name = $('#name').val();
       var handle = name.split(' ').join('-').toLowerCase();
       handle = handle.split('"').join('');
       $("#handle").val(handle + "-utm_campaign-la1");
    });
};

App.Shopify.initProduct = function () {
    var product_id = $('#product_id').val();
    if(product_id) {
        let url = window.location.origin + '/shopify-filter/load/' + product_id;
        $.ajax({
            type: "GET",
            url: url,
            success: App.Shopify.InitCallback,
            error: function(data){
                App.Global.ToastMessage(data);
            }
        });
    }
};

App.Shopify.InitCallback = function(response) {
    var product = response.data.product;
    var collections = response.data.collections;
    var productTags = response.data.productTags;

    App.Shopify.productCollectionsArray = product.product_type.split(", ");
    // App.Shopify.productCollectionsArray = ["New Arrivals", "Dresses", "Short Dresses"];

    $.each(App.Shopify.productCollectionsArray, function (key, value) {
        if(value == "Dresses") {
            App.Shopify.isCollectionDresses = true;
            $('.base-collection-holder').css('display', 'block');
        }
    });

    if (collections.length != 0) {
        $.each(collections, function (key, value) {
            if(App.Shopify.isCollectionDresses == true & value.id == 133) {
                $('#collections').append(App.Shopify.HiddenCollectionsTemplate(value));
            } else {
                $('#collections').append(App.Shopify.CollectionsTemplate(value));
            }
        });
    }else{
        $('#filter_products_show').css('display', 'none');
        $('#collections').css('display', 'block');
        $('#collections').append('<label style="color:#FF5454">There are no Collections for this Product. Please enter Collections first!</label>');
        return false;
    }
    if (productTags.length != 0){
        $.each(productTags, function(key, value) {
            $('#collections input').each(function () {
                if ($(this).attr('data-collection-id') == value.filter_collections_id){
                    $(this).prop("checked", true);
                }
            })
        });
    }
    $('#collections').css('display', 'block');
    $('#filter_products_show').css('display', 'block');

    $('#collections input').each(function () {
        if (this.checked){
            App.Shopify.LoadStyles($(this).attr('data-collection-id'), product.id);
        }
    });
    App.Shopify.Checked();
};

App.Shopify.LoadStyles = function (collection_id, product_id) {
    var url = window.location.origin + '/shopify-filter/loadStyles/' + collection_id + '/' + product_id;
    $.ajax({
        type: "GET",
        url: url,
        success: App.Shopify.LoadStylesCallback,
        error: function(data){
            App.Global.ToastMessage(data);
        }
    });
};

App.Shopify.LoadStylesCallback = function (response) {
    var productTags = response.data.productTags;
    var collectionStyles = response.data.collectionStyles;
    var collection = response.data.collection;
    $('.collect-style').clone().appendTo('.filter-holder').attr('class', 'col-lg-3 col-md-3 well styles'+collection.id);

    $.each(collectionStyles, function(key, value) {
        if(App.Shopify.isCollectionDresses == true & (value.id == 133 || value.id == 124 || value.id == 123 || value.id == 121)) {
            $('.styles' + collection.id).append(App.Shopify.HiddenStylesTemplate(value, collection.id));
        } else {
            $('.styles' + collection.id).append(App.Shopify.StylesTemplate(value, collection.id));
        }
    });

    if (productTags.length != 0){
        $.each(productTags, function(key, value) {
            var check = $('.styles' + collection.id + ' input');
            check.each(function () {
                if ($(this).attr('data-collection-id') == value.filter_collection_styles_id){
                    $(this).prop("checked", true);
                }
            })
        });
    }

    $('.styles' + collection.id + ' .style-name').html('Styles for ' + collection.name);
    $('.styles' + collection.id).css('display', 'block');

    App.Shopify.initChangeCollections();
};

App.Shopify.HiddenStylesTemplate = (value, id) => {
    return`
    <div class="row return-entry" id="${value.id}" style="display: none">
        <input id="${id}${value.id}" style="float: left; margin-right:6px;" class="check" type="checkbox" value='0' data-collection-id="${value.id}" data-style-tagname="${value.tag_name}">
        <label for="${id}${value.id}" style="">${value.name}</label>
    </div>
    `;
};

App.Shopify.StylesTemplate = (value, id) => {
    return`
    <div class="row return-entry" id="${value.id}">
        <input id="${id}${value.id}" style="float: left; margin-right:6px;" class="check" type="checkbox" value='0' data-collection-id="${value.id}" data-style-tagname="${value.tag_name}">
        <label for="${id}${value.id}" style="">${value.name}</label>
    </div>
    `;
};

App.Shopify.CollectionsTemplate = (value) => {
    return`
    <div class="row return-entry" id="${value.id}">
        <input id="collect${value.id}" style="float: left; margin-right:6px;" class="check" type="checkbox" value='0' data-collection-id="${value.id}">
        <label for="collect${value.id}" style="">${value.name}</label>
    </div>
    `;
};

App.Shopify.HiddenCollectionsTemplate = (value) => {
    return`
    <div class="row return-entry" id="${value.id}" style="display: none">
        <input id="collect${value.id}" style="float: left; margin-right:6px;" class="check" type="checkbox" value='0' data-collection-id="${value.id}">
        <label for="collect${value.id}" style="">${value.name}</label>
    </div>
    `;
};

App.Shopify.CheckColor = function () {
    var tags = $("#product_tags").val();
    tags = tags.split(',');

    $('.row-color').each(function () {
        let el = $(this);
        var color = "co-" + el.find('.check').val();
        $.each(tags, function (index, tag) {
            if (color == $.trim(tag)) {
                el.find('.check').attr('checked', true);
            }
        })
    })
};

App.Shopify.CheckCollections = function() {
    $('.occasion-style input').each(function () {
        if ($(this).is(':checked')){
            $('.occasion-style').css("background-color", "#baffba");
            App.Shopify.isCollectionGroupOneOk = true;
        }
    })
    $('.size-style input').each(function () {
        if ($(this).is(':checked')){
            $('.size-style').css("background-color", "#baffba");
            App.Shopify.isCollectionGroupTwoOk = true;
        }
    })
}

App.Shopify.Checked = function () {
    $('#collections input').click(function (e) {
        if (this.checked){
            var product_id = $('#product_id').val();
            App.Shopify.LoadStyles($(this).attr('data-collection-id'), product_id);
        }else{
            var cls = 'styles' + $(this).attr('data-collection-id');
            $('.' + cls).remove();
        }
    })
};

App.Shopify.CheckForSubmit = function() {
    $('#new_product_submit').on('click', function () {
        if (App.Shopify.isCollectionDresses == true & (App.Shopify.isCollectionGroupOneOk == false || App.Shopify.isCollectionGroupOneOk == false)) {
            App.Global.ToastMessage("You must select at least one of the collections offered (Formal or Casual and Maxi or Short Dresses)!");
            return false;
        } else {
            App.Shopify.Submit();
        }
    })
}

App.Shopify.Submit = function () {
    var name = $('#name').val();
    var vendor = $('#vendor').val();
    var handle = $('#handle').val();
    var date = $('#date').val();
    var time = $('#time').val();
    var shopifyProductId = $('#shopify_product_id').val();

    var tags = [];
    $('.filter-product-tag-holder').each(function () {
        tags.push($(this).find('.filter-product-tag').html());
    });
    var colorNames = [];
    var colors = [];
    $('.row-color').each(function () {
        if ($(this).find('.check').is(':checked')) {
            colors.push(prefixes['color'] + $(this).find('.check').val());
            colorNames.push($(this).find('.color-label').html());
        }
    });
    var styles = [];
    var filters = [];
    var product_id = $('#product_id').val();
    $('#collections input').each(function () {
        if (this.checked) {
            var collection_id = $(this).attr('data-collection-id');
            $('.styles' + collection_id + ' input').each(function () {
                if (this.checked) {
                    var row = {};
                    var style_id = $(this).attr('data-collection-id');
                    row.style_id = style_id;
                    row.collection_id = collection_id;
                    filters.push(row);
                    styles.push(prefixes['style'] + $(this).attr('data-style-tagname'));
                }
            })
        }
    });
    var sizes = [];
    var variants = [];
    $('.variant').each(function () {
        var row = {};
        let el = $(this);
        let selectedItem = el.find('.select-size option:selected');
        row.is_size = selectedItem.val();
        row.size = selectedItem.text();
        row.position = selectedItem.attr('data-size-position');

        row.inventory = el.find("input[name*='inventory']").val();
        row.sku = el.find("input[name*='sku']").val();
        row.price = el.find("input[name*='price']").val();
        row.wight = el.find("input[name*='wight']").val();
        row.color = el.find("input[name*='color']").val();
        variants.push(row);
        sizes.push(prefixes['size'] + row.is_size);
    });

    var allTags = $.merge(tags, colors);
    $.merge(allTags, sizes);
    $.merge(allTags, styles);

    var product_type = $("#product_type").val();

    // Validation

    if (App.Shopify.CheckIfUnique(sizes) == false) {
        App.Global.ToastMessage("Please pick different size for each collection !!!");
        return false;
    }
    /*
    if (styles.length < 1) {
        App.Global.ToastMessage("Please pick at least one style !!!");
        return false;
    }
    */
    if (colors.length < 1) {
        App.Global.ToastMessage("Please pick at least one color !!!");
        return false;
    }

    $.each(variants, function (key, value) {
        if (value.is_size == "") {
            App.Global.ToastMessage("All variants must have size !!!");
            $.stopPropagation();
        }
        if (value.color == "") {
            App.Global.ToastMessage("All variants must have color !!!");
            $.stopPropagation();
        }
        if (value.price == "") {
            App.Global.ToastMessage("All variants must have price !!!");
            $.stopPropagation();
        }
        if (value.wight == "") {
            App.Global.ToastMessage("All variants must have wight !!!");
            $.stopPropagation();
        }
        if (value.sku == "") {
            App.Global.ToastMessage("All variants must have SKU !!!");
            $.stopPropagation();
        }
        if (value.sku.indexOf("-") == -1) {
            App.Global.ToastMessage("Value SKU is not valid !!!");
            $.stopPropagation();
        }
    });

    $(".panel.panel-default .col-md-6").each(function () {
        if ($(this).find("input").val() == "") {
            App.Global.ToastMessage("Field " + $(this).find("input").attr('id') + " canot be empty !!!");
            $.stopPropagation();
        }
    });

    let url = window.location.origin + '/shopify/products/new/save';
    $.ajax({
        headers: {
            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
        },
        type: "POST",
        url: url,
        data: {
            variants: variants,
            allTags: allTags,
            filters: filters,
            name: name,
            vendor: vendor,
            handle: handle,
            date: date,
            time: time,
            product_type: product_type,
            shopifyProductId: shopifyProductId
        },
        success: App.Shopify.SubmitCallback,
        error: function (data) {
            App.Global.ToastMessage(data);
        }
    });
};

App.Shopify.SubmitCallback = function (resp) {

    App.Global.ToastMessage("Product is successfully saved !!!");
    let url = prefixes['site'] + 'admin/products/' + resp.data.shopifyProduct['id'];
    window.location.href = url;
};

App.Shopify.CheckIfUnique = function (myArray) {
    for (var i = 0; i < myArray.length; i++)
    {
        if (myArray.indexOf(myArray[i]) !== myArray.lastIndexOf(myArray[i])) {
            return false;
        }
    }
    return true;
};