App.Counter = {};

App.CountingHomeIndex = function() {
    //App.Counter.Barcode();
    App.Counter.LoadCounterInit();
    $('#isOrder').val('');
    $('#count_id').val('');
    App.Counter.HidePopup();
    App.Counter.ChangeCounter();
    App.Counter.SubmitCounts();

};

App.Counter.barcodeAction = false;
App.Counter.barcodeOutput = '';

App.Counter.LoadCounterInit = function() {
    url = window.location.origin + '/counters/getCountings';
    $.ajax({
        type: "GET",
        url: url,
        success: App.Counter.LoadCounterOrderCallback,
        error: function(data){
            console.log(data);
        }
    });
};

App.Counter.Edit = function () {
    $('.count-edit').click(function () {
        $('#count_id').val($(this).attr('id'));

        $('#code').val($(this).parent().parent().find('.item-bar').html());
        $('#count').val($(this).parent().parent().find('.item-count').html());
        var storage = $(this).parent().parent().find('.item-storage').html();

        $('form input[type=radio]').each(function () {
            if($(this).val() == storage){
                $(this).prop('checked', true);
            }
        });
        $('#results').children().remove();
        $('.empty').css('display', 'none');

    })
};
App.Counter.LoadCounterOrderCallback = function(result) {
    $('.input-error').html('');
    var user = result.data.user;
    var countings = result.data.countings;
    if(countings.length == 0){
        $('.empty').css('display', 'none');
    }else{
        $('.empty').css('display', 'block');
    }

    $('.welcome').html('Welcome ' + user.name);
    $('#code').val('');
    $('#count').val('');
    $('#count_id').val('');
    $('form input[type=radio]:checked').prop('checked', false);
    $('#results').children().remove();

    $.each(countings, function(key, value){
        $('#results').append(App.Counter.LoadCounterOrderTemplate(value));
    });
    App.Counter.Edit();

};


App.Counter.LoadCounterOrderTemplate = function(countings) {
    var variant = countings.product_variant;
    var user = countings.user;
    var img = "";
    var title = "Product not found!";
    if (variant != null) {
        img = variant.product.images;
        title = variant.product.title;
    }
    return `
<div class="row return-entry">
    <div class="row-data">
        <div class="col-lg-4 col-md-4 item-bar text-center">
            <img src="${img}" style="width:100px" />
            <br />
            ${title}
        </div>
        <div class="col-lg-2 col-md-2 item-bar">${user.name}</div>
        <div class="col-lg-2 col-md-2 item-bar">${countings.barcode}</div>
        <div class="col-lg-1 col-md-1 item-count">${countings.inventory_counts}</div>
        <div class="col-lg-2 col-md-2 item-storage">${countings.storage_type}</div>
        <div class="col-lg-1 col-md-1 actions">
            <a id="${countings.id}" class="btn btn-danger count-edit" title="Suspended">Edit</a>
        </div>
    </div>
</div>
`;
};



/*
App.Counter.LoadBarcode = function() {
    if (App.Counter.barcodeAction) {
        if ($('#isOrder').val() === ''){
            App.url = window.location.origin + '/packer/Order/' + $('#code').val();
        }else{
            App.url = window.location.origin + '/packer/OrderItem/' + $('#code').val() + '/' + $('#isOrder').val();
        }
        $.ajax({
            type: "GET",
            url: App.url,
            success: App.Counter.LoadCounterOrderCallback,
            error: function(){
                $('#code').val('');
            }
        });
        App.Counter.barcodeAction = false; //reset type listener
    }
};
/*
App.Counter.Barcode = function () {
    $(document).on({
        keypress: function (e) {
            var code = e.keyCode || e.which;
            App.Counter.barcodeOutput +=  String.fromCharCode(code);
            if (code == 13) {
                App.Counter.barcodeAction = true;
                $('#code').val(App.Counter.barcodeOutput);
                App.Counter.barcodeOutput = "";
                App.Counter.LoadBarcode();
            }
        }
    });
}
*/
App.Counter.CheckPin = function () {
    $(document).off('keypress');
    $('#pincode').keypress(function (e) {
        e.stopPropagation();
        var key = e.keyCode || e.which;
        if(key == 13)  // the enter key code
        {
            App.Counter.LoadCounter();
        }

    });
};

App.Counter.LoadCounter = function() {
    url = window.location.origin + '/users/order/' + $('#pincode').val();
    $.ajax({
        type: "GET",
        url: url,
        success: function() {
            window.location.reload();
        },
        error: function(data){
            console.log(data);
            $('#pincode').val('');
            $('.error-pin').html('Pin code not recognized');
        }
    });
};



App.Counter.SubmitCounts =function () {
    $('#subCount').click(function () {
        var barCode = $('#code').val();
        var itemCount = $('#count').val();
        var userId = $('#user_id').val();
        var storageType = typeof($('form input[type=radio]:checked').val()) === "undefined" ? "" :  $('form input[type=radio]:checked').val();
        var count_id = $('#count_id').val();
        if(barCode == ""){
            $('.input-error').html('Please enter Bar Code');
            return;
        }
        if(itemCount == ""){
            $('.input-error').html('Please enter Item Count');
            return;
        }
        if(storageType == ""){
            $('.input-error').html('Please select Storage Type');
            return;
        }

        if(userId == ""){
            $('.input-error').html('Please pick staff member');
            return;
        }

        url = window.location.origin + '/counters/save';
        $.ajax({
            type: "POST",
            url: url,
            data: {barCode:barCode, itemCount:itemCount, storageType:storageType, count_id:count_id, user_id: userId},
            success: App.Counter.LoadCounterInit,
            error: function(data){
                //console.log(data);
                //$(".error-massage").html("Error! Order not found");
            }
        })
    });
};





App.Counter.ChangeCounter = function () {
    $('.btn.change-packer').click(function(){
        App.Counter.ShowPopup();
    });
};
App.Counter.ShowPopup = function() {
    $('#pincode').val('');
    $('.error-pin').html('');
    $('.pin-popup').css('display', 'block');
    $('.panel.panel-default').css('display', 'none');

    App.Counter.CheckPin();

    setTimeout(function(){
        $('#pincode').focus();
    }, 1);
};
App.Counter.HidePopup = function() {
    $('.pin-popup').css('display', 'none');
    $('.panel.panel-default').css('display', 'block');
};




