App.ShopifyProductsHomeIndex = function() {
    $('.publish_date').datepicker({});

    $('#runSortScript').on('click', App.ShopifyProducts.SortOnShopify);
};
App.ShopifyProducts = {};

App.ShopifyProducts.SortOnShopify = function() {
    let url = window.location.origin + '/shopify/run-sort-script';
    $.ajax({
        type: "GET",
        url: url,
        success: App.ShopifyProducts.SortComplete,
        error: function(data){
            App.Global.ToastMessage(data);
        }
    });
};

App.ShopifyProducts.SortComplete = function() {
    $('.run-success').css({display: 'block'});
};