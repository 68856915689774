App.Picker = {};

App.PickerHomeIndex = function() {
    App.Picker.ShowPopup();
    App.Picker.CheckPin();
    App.Picker.ChangePicker();
    App.Picker.Barcode();
    App.Picker.Timeouth();

};

App.Picker.barcodeAction = false;
App.Picker.barcodeOutput = "";

App.Picker.Timeouth = function () {
    var timeout = null;
    var timee = '300000'; // default time for session time out.
    $(document).bind('click keyup mousemove', function(event) {
        if (timeout !== null) {
            clearTimeout(timeout);
        }
        timeout = setTimeout(function() {
            timeout = null;
            console.log('Document Idle since '+timee+' ms');
            App.Picker.ShowPopup();
        }, timee);
    });
};
App.Picker.DeleteOrder = function () {
    $('.btn.btn-danger.delete').click(function(e){
        if (!confirm("Do you want to delete")){
            return false;
        }
        var id = $(this).attr('id');
        url = window.location.origin + '/users/deleteOrder/' + id;
        $.ajax({
            type: "GET",
            url: url,
            success: App.Picker.DeleteOrderCallback,
            error: function(data){
                console.log(data);
            }
        });
    });
};

App.Picker.DeleteOrderCallback = function (result) {
    if (result.errors == false) {
        $('#code').val('');
        $('#results').children().remove();
        var count = 1;
        $.each(result.data.allShipments, function(key, value) {
            $('#results').append(App.Picker.DeleteOrderTemplate(count, value));
            count++;
        });
        App.Picker.DeleteOrder();
    }
};


App.Picker.DeleteOrderTemplate = (count, value) => {
    return `
<div class="row return-entry">
    <div class="row-data">
        <div class="col-lg-1 col-md-2">${count}</div>
            <div class="col-lg-5 col-md-2">${value.tracking_number}</div>
            <div class="col-lg-5 col-md-2">${value.number}</div>
            <div class="col-lg-offset-1 col-md-offset-1 actions">
            <a id="${value.id}" class="btn btn-danger delete" title="Delete">Delete</a>
            </div>
        </div>
    </div>
</div>
`;
};


App.Picker.LoadPicker = function() {
    url = window.location.origin + '/users/order/' + $('#pincode').val();
    $.ajax({
        type: "GET",
        url: url,
        success: App.Picker.LoadPickerOrderCallback,
        error: function(data){
            console.log(data);
            $('#pincode').val('');
            $('.error-pin').html('Pin code not recognized');
        }
    });
};

App.Picker.LoadPickerOrderCallback = function(result) {
    console.log(result);
    $('.error-pin').html('');
    $('.welcome').html('Welcome ' + result.data.user.name);
    $('#code').val('');


    $('#results').children().remove();
    var count = 1;
    $.each(result.data.shipments, function(key, value) {
        $('#results').append(App.Picker.LoadPickerTemplate(count, value));
        count ++;
    });


    App.Picker.HidePopup();
    $('.image').attr('src', result.data.user.image);
    App.Picker.DeleteOrder();
};


App.Picker.LoadPickerTemplate = (count, value) => {
    return `
<div class="row return-entry">
    <div class="row-data">
        <div class="col-lg-1 col-md-2">${count}</div>
            <div class="col-lg-5 col-md-2"> ${value.tracking_number}</div>
            <div class="col-lg-5 col-md-2">${value.number}</div>
            <div class="col-lg-offset-1 col-md-offset-1 actions">
            <a id="${value.id}" class="btn btn-danger delete" title="Delete">Delete</a>
            </div>
        </div>
    </div>
</div>
`;
}


App.Picker.LoadBarcode = function() {
    if (App.Picker.barcodeAction) {
        App.url = window.location.origin + '/shipstation/loadByBarcode/' + $('#code').val();
        $.ajax({
            type: "GET",
            url: App.url,
            success: App.Picker.LoadBarcodeCallback,
            error: function(data){
                $('#code').val('');

                App.Global.ToastMessage(data.responseJSON.errors.message[0]);
            }
        });
        App.Picker.barcodeAction = false; //reset type listener
    }
};

App.Picker.LoadBarcodeCallback = function(result) {
    console.log(result);
    if (result.errors == false) {
        $('#code').val('');
        $('#results').children().remove();
        var count = 1;
        $.each(result.data.allShipments, function(key, value) {
            $('#results').append("<div class=\"row return-entry\"><div class=\"row-data\"><div class=\"col-lg-1 col-md-2\">" + count + "</div><div class=\"col-lg-5 col-md-2\">" + value.tracking_number + "</div><div class=\"col-lg-5 col-md-2\">" + value.number + "</div><div class=\"col-lg-offset-1 col-md-offset-1 actions\"><a id=\"" + value.id + "\" class=\"btn btn-danger delete\" title=\"Delete\">Delete</a></div></div></div></div>")
            count++;
        });
        App.Picker.DeleteOrder();
    }
};

App.Picker.Barcode = function () {

    $(document).on({
        keypress: function (e) {
            var code = e.keyCode || e.which;
            App.Picker.barcodeOutput +=  String.fromCharCode(code);
            if (code == 13) {
                App.Picker.barcodeAction = true;
                $('#code').val(App.Picker.barcodeOutput);
                App.Picker.barcodeOutput = "";
                App.Picker.LoadBarcode();
            }
        }
    });
}

App.Picker.CheckPin = function () {
    $('#pincode').keypress(function (e) {
        e.stopPropagation();
        var key = e.keyCode || e.which;
        if(key == 13)  // the enter key code
        {
            App.Picker.LoadPicker();
        }

    });
}

App.Picker.ChangePicker = function () {
    $('.btn.change-picker').click(function(){
        App.Picker.ShowPopup();
    });
}
App.Picker.ShowPopup = function() {
    $('#pincode').val('');
    $('.error-pin').html('');
    $('.pin-popup').css('display', 'block');
    $('.panel.panel-default').css('display', 'none');

    setTimeout(function(){
        $('#pincode').focus();
    }, 1);
};
App.Picker.HidePopup = function() {
    $('.pin-popup').css('display', 'none');
    $('.panel.panel-default').css('display', 'block');
};
