App.ShopifySearch = {};
App.ShopifySearch.myChart = null;

App.ShopifySearchesListIndex = function() {
    App.ShopifySearch.LoadChartData();
    App.ShopifySearch.LoadListData();
    App.ShopifySearch.SubmitDate();
    App.ShopifySearch.MakeYear();
};
App.ShopifySearch.MakeYear = function () {
    var d = new Date();
    var curYear = d.getFullYear();
    for (i=0; i<3; i++) {
        truYear = curYear - i;
        $('.year').append(App.ShopifySearch.YearTemplate(truYear));
    }
}
App.ShopifySearch.YearTemplate = function (truYear) {
    return`
        <option value="${truYear}">${truYear}</option>
    `;
}
App.ShopifySearch.SubmitDate = function () {
    $('.submit').on('click', function () {
        App.ShopifySearch.LoadChartData();
    })
}

App.ShopifySearch.LoadChartData = function () {
    var date = $('.month').val() + "-" + $('.year').val();
    if(date == "-"){
        var d = new Date();
        var date = (d.getMonth() + 1) + '-' + d.getFullYear();
    }

    let url = window.location.origin + '/shopify/products/search/chart/' + date;
    $.ajax({
        type: "GET",
        url: url,
        success: App.ShopifySearch.LoadChartDataCallback,
        error: function(){
            App.Global.ToastMessage("For this date there are no results !!!");
        }
    });
}

App.ShopifySearch.LoadByDay = function (date) {
    var splitDate = date.split(" ");
    var clickDate = $('.year').val() + "-" +$('.month').val() + "-" + splitDate[0];
    $('#date').val(clickDate);
    App.ShopifySearch.LoadListData(1, clickDate);
}

App.ShopifySearch.LoadChartDataCallback = function(response) {
    $('#myChart').empty();
    var chartData = response.data.searchData;
    var inputData = chartData[0].adate.split('-');
    // $('#date').val(inputData[1] + '-' + inputData[0]);
    $('.month').val(inputData[1]);
    $('.year').val(inputData[0]);

    var monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

    var labels = [];
    var data = [];

    $.each(chartData, function (key, value) {
        var dat = value.adate.split('-');
        var month = monthNames[dat[1].replace(/^0+/, '') - 1];

        labels.push(dat[2] + " " + month);
        data.push(value.total);
    })
    App.ShopifySearch.ShowChart(labels, data);
}

App.ShopifySearch.ShowChart = function (labels, data) {
    var ctx = document.getElementById("myChart").getContext('2d');
    if (App.ShopifySearch.myChart != null) {
        App.ShopifySearch.myChart.destroy();
    }
    App.ShopifySearch.myChart = new Chart(ctx, {
        type: 'line',
        data: {
            labels: labels,
            datasets: [{
                label: 'Searches per day',
                data: data,
                backgroundColor: [
                    'rgba(255, 99, 132, 0.2)'
                ],
                borderColor: [
                    'rgba(255,99,132,1)'
                ],
                borderWidth: 1
            }]
        },
        options: {
            onClick:function(c,i){
                e = i[0];
                var x_value = this.data.labels[e._index];
                App.ShopifySearch.LoadByDay(x_value);
            },
            scales: {
                yAxes: [{
                    ticks: {
                        beginAtZero:true
                    }
                }]
            }
        }
    })
};

App.ShopifySearch.LoadListData = function (page = null, date = "") {
    if(page == null){page = 1};
    if(date == ""){
        var d = new Date();
        var date = d.getFullYear() + '-' +  (d.getMonth() + 1) + '-' + d.getDate();
    }
        let url = window.location.origin + '/shopify/products/search/list/data';
        $.ajax({
            type: "GET",
            url: url,
            data: {'date': date, 'page': page},
            success: App.ShopifySearch.LoadListDataCallback,
            error: function () {
                App.Global.ToastMessage("For this date there are no results !!!");
            }
        });
    };

App.ShopifySearch.LoadListDataCallback = function (response) {
    $('#list').empty();
    $('#paginate').empty();
    var listData = response.data.searchData.data;
    $('#paginate').append(App.ShopifySearch.ListPaginateTemplate(response.data.searchData));
    $.each(listData, function (key, value) {
        $('#list').append(App.ShopifySearch.ListTemplate(value));
    })
    $('.pagination li').on('click', function (e) {
        var page = $(this).attr('data-page');
        if(page != 0) {
            App.ShopifySearch.LoadListData(page, $('#date').val());
        }
    })
};

App.ShopifySearch.ListTemplate = (value) => {
    return`
        <div class="row return-entry">
            <div class="row-data">
                <div class="col-lg-4 col-md-4">${value.search_term}</div>
                <div class="col-lg-3 col-md-3">${value.product_id === 'null' ? 'search' : 'product' }</div>
                <div class="col-lg-3 col-md-3">${value.adate}</div>
                <div class="col-lg-2 col-md-2">${value.total}</div>
            </div>
        </div>
    `;
}

App.ShopifySearch.ListPaginateTemplate = (value) => {

    var prev = value.current_page > 1 ? value.current_page -1 : 0;
    var next = value.current_page < value.last_page ? value.current_page + 1 : 0;
    var arrpages = [];

    for(i=3; i>0; i--) {
        if(value.current_page - i > 0) {
            arrpages.push(value.current_page - i);
        }
    }
    arrpages.push(value.current_page);

    for(i=1; i<4; i++) {
        if(value.current_page + i < value.last_page + 1) {
            arrpages.push(value.current_page + i);
        }
    }
    var first = "";
    var list = "";
    var last ="";

    if (value.current_page > 6) {
        first =
            `
            <li data-page="1"><span>1</span></li>
            <li data-page="2"><span>2</span></li>
            <li data-page="3"><span>3</span></li>
            <li data-page="0"class="disabled"><span>...</span></li>
            `;
    }

    if (value.current_page < (value.last_page - 5)) {
        last =
            `
            <li data-page="0"class="disabled"><span>...</span></li>
            <li data-page="${value.last_page - 2}"><span>${value.last_page - 2}</span></li>
            <li data-page="${value.last_page - 1}"><span>${value.last_page - 1}</span></li>
            <li data-page="${value.last_page}"><span>${value.last_page}</span></li>
            `;
    }


    $.each(arrpages, function (index, pagenum) {
        if(pagenum == value.current_page) {
            list += '<li data-page="' + pagenum + '"class="active"><span>' + pagenum + '</span></li>'
        } else {
            list += "<li data-page=" + pagenum + "><span>" + pagenum + "</span></li>"
        }
    });

    return`
        <ul class="pagination">
            <li ` + ((prev === 0) ? `class="disabled"` : `class=""`) + ` data-page="${prev}"><span>prev</span></li>
                ${first}            
                ${list}
                ${last}
            <li ` + ((next === 0) ? `class="disabled"` : `class=""`) + ` data-page="${next}"><span>next</span></li>
        </ul>
    `;
}