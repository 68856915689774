App.Returns = {};

App.Returns.currentShopifyOrder = null;
App.ReturnsHomeIndex = function() {
    App.Returns.LoadOrder();
};
App.Returns.CheckBox = function() {
    $('.check').click(function() {
        if (this.checked){
            if($(this).parent().is(':nth-child(2n+1)') == true) {
                $(this).parent().css( "background-color", "#96d796" );
            }else{
                $(this).parent().css( "background-color", "rgb(127, 174, 127)" );
           }
        }else{
            if($(this).parent().is(':nth-child(2n+1)') == true) {
                $(this).parent().css( "background-color", "#eee" );
            }else{
                $(this).parent().css( "background-color", "#fff" );
            }
        }

        App.Returns.checkIfRefundAllowed(App.Returns.currentShopifyOrder);
    })
    $('.item-image').click(function () {
        $(this).parent().find(".check").click();
    })
}
App.Returns.LoadOrder = function() {
    $('.order_loaded_container').hide();
    $('.order_loaded_container .refund_option').hide();
    $('.order_loaded_container .return_summary').hide();

    $('#order_number').on('keyup', function (e) {
        var keyCode = e.keyCode || e.which;
        if (keyCode === 13) {
            $(".error-massage-home").html("");
            App.Returns.ajaxLoad();
            return false;
        }
    });
    $('#order_email').on('keyup', function (e) {
        var keyCode = e.keyCode || e.which;
        if (keyCode === 13) {
            $(".error-massage-home").html("");
            App.Returns.ajaxLoad();
            return false;
        }
    });
    $('#load_order_items').on('click', function (e) {
        $(".error-massage-home").html("");
        App.Returns.ajaxLoad();
        return false;
    });
    $('#send_order_items').on('click', function (e) {
        $(".error-massage-home").html("");
        App.Returns.ajaxSubmit();
        return false;
    });
    $('#refreshimg').on('click', function (e) {
        App.Returns.loadCaptcha();
        return false;
    });

};
App.Returns.loadCaptcha = function () {
    url = window.location.origin + '/returns/captcha';
    $.ajax({
        type: "GET",
        url: url,
        success: App.Returns.LoadCaptchaCallback,
        error: function(data){

        }
    });
};
App.Returns.LoadCaptchaCallback = function (response) {
    var captcha = response.data.img_src;
    $("#refreshimg img").attr('src', captcha);

}
App.Returns.ajaxLoad = function() {
    var order_number = $('#order_number').val();
    var orderEmail = $("#order_email").val();
    var captchaCode = $("#captcha_code").val();
    if (captchaCode == "") {
        captchaCode = "noCode";
    }
    if (order_number == '') {
        $(".error-massage-home").html("Please enter a valid order number");
        return false;
    }
    if (orderEmail == ""){
        $(".error-massage-home").html("Please enter a valid email for order");
        return false;
    }
    url = window.location.origin + '/returns/getorder/' + order_number + '/' + orderEmail + '/' + captchaCode;
    $.ajax({
        type: "GET",
        url: url,
        success: App.Returns.LoadOrderCallback,
        error: function(data){
            var captchaCode = $("#captcha_code").val("");
            $('.status').html("");
        }
    });
};
App.Returns.ajaxSubmit = function () {
    var orderId = $("#orderId").val();
    var itemId = [];
    $('input[type=checkbox]').each(function () {
        if (this.checked) {
            itemId.push($(this).attr("data-item-id"));
        }
    });
    if (itemId.length == 0) {
        $(".error-massage-home").html("Please select at least one item");
        return false;
    }
    if (orderId == '') {
        $(".error-massage-home").html("Please enter a valid order number");
        return false;
    }

    isRefund = $('#refund_checkbox').prop('checked') == true ? "1" : "0";
    isLabel = $('#shipping_label_checkbox').prop('checked') == true ? "1" : "0";
    url = window.location.origin + '/returns/updateItems';
    $.ajax({
        type: "POST",
        url: url,
        data: {order_id:orderId, item_id:itemId, isRefund: isRefund, isLabel: isLabel},
        success: App.Returns.SubmitOrderCallback,
        error: function(data){
            //console.log(data);
            $(".error-massage-home").html("Sorry, we do not have that order in our records, please revisit your entry.");
        }
    });
}

App.Returns.SubmitOrderCallback = function (response) {
    //$('#order_email').css('display', 'none');
    $('#items_container').html('');
    $('.pick-title').css("display", "none");
    $('#send_order_items').css("display", "none");
    $(".error-massage-home").html(response.data);
}

App.Returns.LoadOrderCallback = function(response) {
    var isCaptcha = response.data.is_captcha;
    if (isCaptcha == "Yes") {
        $('.captcha').css('display', 'block');
    }else{
        $('.captcha').css('display', 'none');
    }
    var returnOrders = response.data.returnOrders;
    var captcha = response.data.img_src;
    if (captcha == "wrongCode") {
        $('.status').html("Wrong Code");
    }else{
        $('.status').html("");
        $("#refreshimg img").attr('src', captcha);
    }

    if(returnOrders != null && returnOrders != ''){
        $(".error-massage-home").html("You already submitted the request for this order. If the problem persists please contact us.");
        return false;
    }else if (returnOrders == '') {
        $(".error-massage-home").html("Please enter the validation code");
        return false;
    }

    var data = response.data.shopifyOrder;

    var t = data.created_at.split(/[- :]/);
    var orderDate = new Date(Date.UTC(t[0], t[1]-1, t[2], t[3], t[4], t[5]));

    var now = new Date();

    var one_day=1000*60*60*24;    // Convert both dates to milliseconds
    var date1_ms = orderDate.getTime();
    var date2_ms = now.getTime();    // Calculate the difference in milliseconds
    var difference_ms = date2_ms - date1_ms;        // Convert back to days and return
    let days = Math.round(difference_ms/one_day);

    if (days > 45) {
        $(".error-massage-home").html("Your order is older than 30 days, it's not eligible for return.");
        return false;
    }


    $(".error-massage-home").html("");
    $(".email").css("display", "block");
    $("#send_order_items").css("display", "block");
    $(".pick-title").css("display", "block");

    $('.order_form').hide();
    $('.order_loaded_container').show();


    App.Returns.currentShopifyOrder = data;
    App.Returns.FillOrderInfoAndForm(data);


    $("#orderId").val(data.number);
    $('#items_container').html('');
    for (var item in data.line_items) {
        var quantity = data.line_items[item].quantity;
        if(quantity > 1){
            for (var i=0; i<quantity; i++){
                var newItems = response.data.shopifyOrder;
                newItems.line_items[item].quantity = 1;
                $('#items_container').append(App.Returns.LoadLineItemsTemplate(item, newItems));
            }
        }else{
            $('#items_container').append(App.Returns.LoadLineItemsTemplate(item, data));
        }
    }
    App.Returns.CheckBox();
};

App.Returns.FillOrderInfoAndForm = function(data) {
    var customerName = data.customer + "(" + data.email + ")";
    $('.order_info .customer_info').html(customerName);
    $('.order_info .order_number').html(data.number);


    var price = 6.95;

    $('.return_label_option .label_price').html("$" + price.toFixed(2));

    App.Returns.checkIfRefundAllowed(data);


};

App.Returns.CalculateLabelPrice = function() {

    var selectedItems = $('.return-entry .check:checked');
    var weight = parseInt(0);

    if (selectedItems.length > 0) {
        selectedItems.each(function() {
            let grams = $(this).attr('data-item-weight');
            if (grams > 0) {
                weight = parseInt(weight) + parseInt(grams);
            }
        })
    }

    var pounds = Math.ceil(weight / 453.59237);
    var price = parseFloat(6.95);
    if (pounds > 1) {
        // add $2 for each pound over 1 pound. i.e. add $4 for weight of 3.4 pounds
        roundPoundsToMultiply = pounds - 1;
        priceToAdd = parseInt(2 * roundPoundsToMultiply);
        price = price + priceToAdd;
    }
    $('.return_label_option .label_price').html("$" + price.toFixed(2));

};

App.Returns.checkIfRefundAllowed = function (shopifyOrder){

    var returnAmount;

    var totalDiscount = shopifyOrder.total_discounts;
    var totalTax = shopifyOrder.total_tax;
    var totalAmount = 0;
    var totalPrice = 0;

    $('.check').each(function () {
        var checkbox = $(this).parent();
        if (this.checked) {
            var price = checkbox.find(".get-price").attr("data-price");
            var quantity =  1; //checkbox.find(".quantity-select").val();
            totalAmount = totalAmount + (price * quantity);
        }
    });

    $('.check').each(function () {
        var checkbox = $(this).parent();
        var price = checkbox.find(".get-price").attr("data-price");
        var quantity =  1; //checkbox.find(".quantity-select").val();
        totalPrice = totalPrice + (price * quantity);
    });

    totalPrice = totalPrice + totalTax;

    if (totalDiscount > 0 && totalAmount > 0) {
        discount_amount_multiplier = totalAmount / totalPrice;
        discount_amount = totalDiscount * discount_amount_multiplier;
        discount_amount = parseFloat(discount_amount.toFixed(2));
        totalAmount = totalAmount - discount_amount;
    }
    if (totalTax > 0 && totalAmount > 0) {
        discount_amount_multiplier = totalAmount / totalPrice;
        discount_amount = totalTax * discount_amount_multiplier;
        discount_amount = parseFloat(discount_amount.toFixed(2));
        totalAmount = totalAmount + discount_amount;
    }

    returnAmount = totalAmount;

    if (returnAmount == 0) {
        return;
    }

    url = window.location.origin + '/returns/checkForRefund';
    $.ajax({
        type: "GET",
        url: url,
        data: {order_number:shopifyOrder.number, amount: returnAmount},
        success: App.Returns.CheckRefundCallBack,
        error: function(data){
            //console.log(data);
            $(".error-massage-home").html("Sorry, we do not have that order in our records, please revisit your entry.");
        }
    });
};
App.Returns.CheckRefundCallBack = function (response) {
    var refundAllowed = response.data.allowed == '1';
    $('.order_loaded_container .return_summary').show();

    $('#returnTotalAmount').html(response.data.refundAmount);
    $('#newOrdersTotal').html(response.data.totalPrice);
    $('#refundAvailable').html(refundAllowed  == true ? "Yes" : "No" );

    if (refundAllowed) {
        $('.order_loaded_container .refund_option').show();
    }
};
App.Returns.LoadLineItemsTemplate = (item, data) => {
    let grams = 0;
    if (typeof data.line_items[item].grams != 'undefined') {
        grams = data.line_items[item].grams;
    }
    return `
<div class="row return-entry">
    <input class="check" type="checkbox" value='0' data-item-id="${item}" data-item-weight="${grams}" onclick="App.Returns.CalculateLabelPrice()">
    <img src="${data.line_items[item].image}" class="item-image" class="item-image"/>
    <div class="items-title">
        <div style="text-align: center; font-weight: 600; line-height: 30px;">${data.line_items[item].name}</div>
        <div class="get-price" data-price="${data.line_items[item].price}" style="line-height: 30px;">$${data.line_items[item].price}</div>
        <!-- <div class="">Sku: ${data.line_items[item].sku}</div> -->
        <div style="line-height: 30px;">Quantity to return: ${data.line_items[item].quantity}</div>
    </div>
</div>
`;
};