App.ReturnsReturnsListIndex = function() {
    $('.checkAllReturns').click(function() {
        $('.approveCheck').prop('checked', $(this).prop('checked'));
    });

    $('.approveReturns').click(function() {
        var returnIds = $('.approveCheck:checked').map(
            function () {return this.value;}
            ).get();

        $.ajax({
            type: "GET",
            url: '/returns/process/bulk-approve',
            data: {returnIds:returnIds},
            success: function() {
                document.location.reload();
            },
            error: function(data){
                console.log(data);

            }
        });


    });
};